<div class="component-main-container">
    <app-page-header parentTitle="{{ 'logs.logs-parent-label' | translate }}"
        pageTitle="{{ 'logs.form-filter-user' | translate }}">
    </app-page-header>

    <!--Corpo do componente-->
    <div class="row">
        <div class="col-lg-5 col-xl-4 col-sm-12">
            <div class="component-forms-box">
                <app-logs-form></app-logs-form>

                <div class="form-fields-box" [ngClass]="!filterBox ? 'closed' : ''" [formGroup]="filterLogsForm">
                    <h2 (click)="filterBoxOpen()">
                        {{ "logs.form-filter-label" | translate }}
                    </h2>
                    <hr [ngClass]="!filterBox ? 'hr-open' : ''" />

                    <div class="form-step">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "logs.form-username" | translate}}</mat-label>
                            <input matInput formControlName="username"
                                [required]="!filterLogsForm.value.companySelected" />
                        </mat-form-field>
                    </div>

                    <div class="form-step">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "logs.form-query-label" | translate }}</mat-label>
                            <input matInput formControlName="queryName" />
                        </mat-form-field>
                    </div>


                    <div class="form-step">
                        <!-- Campo para realizar a busca das empresas -->
                        <mat-form-field appearance="outline">
                            <mat-label> {{'logs.form-company' | translate}} </mat-label>
                            <input matInput formControlName="company" #companyNameFilter
                                [required]="!filterLogsForm.value.username && !filterLogsForm.value.companySelected">
                            <mat-icon class="component-search-icon"
                                (click)="searchCompanyFilter(companyNameFilter.value)" svgIcon="bx-search"></mat-icon>
                        </mat-form-field>
                    </div>

                    <!-- Loading -->
                    <mat-progress-bar class="mb-2" *ngIf="companyLoadingFilter" mode="indeterminate"></mat-progress-bar>

                    <!-- Lista das empresas filtradas -->
                    <div class="form-step" *ngIf="companyListFilter.length > 0 ">
                        <mat-form-field appearance="outline">
                            <mat-label> {{'logs.form-select-company' | translate}} </mat-label>
                            <mat-select formControlName="companySelected" #value
                                [required]="!filterLogsForm.value.username">
                                <mat-option appereance=" outline" *ngFor="let company of companyListFilter"
                                    [value]="company.id">
                                    {{company.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-step">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "logs.form-date" | translate }}</mat-label>

                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate formControlName="startDateForm" name="startDateForm"
                                    placeholder="Start date" />
                                <input matEndDate formControlName="endDateForm" name="endDateForm"
                                    placeholder="End date" />
                            </mat-date-range-input>

                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>

                    <button title="{{ 'logs.form-filter-button' | translate }}" class="btn btn-success"
                        (click)="filterUserTraceLog(null, 10, null)" *ngIf="!filterLoading">
                        {{ "logs.form-filter-button" | translate }}
                    </button>
                    <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
                </div>
            </div>
        </div>

        <!-- Sessão tabela -->
        <div class="col-lg-7 col-xl-8 col-sm-12">
            <app-table-header [itensNumber]="paginationProperties.count" [totalNumber]="paginationProperties.total"
                [hasCSV]="true" [hasPDF]="true" (downloadCSV)="generateFileData(null, 'CSV')"
                (downloadPDF)="generateFileData(null, 'PDF')" [loadingData$]="fileLoading"></app-table-header>
            <mat-progress-bar class="mb-2" *ngIf="filterLoading" mode="indeterminate"></mat-progress-bar>

            <div class="component-table-box">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

                    <!-- Username Column -->
                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "logs.form-filter-user" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.user.username }}</td>
                    </ng-container>

                    <!-- Datetime Column -->
                    <ng-container matColumnDef="datetime">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "logs.form-logDatetime" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{formatDate(element.datetime)}}</td>
                    </ng-container>

                    <!-- Operation Column -->
                    <ng-container matColumnDef="operation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "logs.form-operation" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.operation | translate }}</td>
                    </ng-container>

                    <!-- RequestedField Column -->
                    <ng-container matColumnDef="query">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "logs.form-query-label" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.query }}</td>
                    </ng-container>

                    <!-- RequestedField Column -->
                    <ng-container matColumnDef="payload">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "logs.form-payload-label" | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                            on-mouseleave="element.view = false">
                            <button (click)="openDialog(element)" [ngClass]="element.view === true ? 'hover' : ' '"
                                class="btn btn-success view">
                                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
                    (page)="pageChanged($event)" aria-label="Selecione as páginas de Logs" class="mat-paginator-sticky">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>


<ng-template #dialogTemplate let-dialogRef="dialogRef">
    <div class="dialogRef">
        <h2 mat-dialog-title>{{ "logs.form-payload-view" | translate }}</h2>
        <mat-dialog-content class="mat-typography">

            <pre [innerHTML]="choosenPayload"></pre>
            <br>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-flat-button color="success" mat-dialog-close>OK</button>
        </mat-dialog-actions>
    </div>
</ng-template>