import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_USER_TRACE_LOG } from 'src/app/graphql/logs.graphql';

@Injectable({
    providedIn: 'root'
})
export class userTraceLogService {

    constructor(private apollo: Apollo) { }

    /**
      * Função responsável por filtrar os logs
      */
    filterLogs(
        company: string | null,
        query: any | null,
        startDatetime: any | null,
        endDatetime: any | null,
        userName: any | null,
        startCursor: string | null,
        endCursor: string | null,
        first: number | null,
    ): any {

        return this.apollo.watchQuery({
            query: FILTER_USER_TRACE_LOG,
            fetchPolicy: 'network-only',
            variables: {
                company,
                query_Icontains: query,
                datetime_Gte: startDatetime,
                datetime_Lte: endDatetime,
                user_Username_Icontains: userName,
                first,
                after: startCursor,
                before: endCursor
            }
        })
    }

}