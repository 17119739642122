<div class="component-main-container">
  <app-page-header parentTitle="{{ 'report.reports-label' | translate }}"
    pageTitle="{{ 'report.generate-report-label' | translate }}">
  </app-page-header>

  <app-painel-fav *ngIf="selectedInstallationsView" [options]="selectedInstallations"
    [selectedOption]="selectedInstallations"></app-painel-fav>

  <div class="row">
    <!-- Form Div -->
    <div class="col-lg-5 col-xl-4 col-md-12">
      <!-- Formulário para geração de relatórios -->
      <div class="component-forms-box">
        <div class="form-fields-box" [ngClass]="!genFormBox ? 'closed' : ''" [formGroup]="genForm">
          <h2 (click)="genFormBoxOpen()">
            {{ "report.create-form-title" | translate }}
          </h2>
          <hr [ngClass]="!genFormBox ? 'hr-open' : ''" />

          <!-- Passo de preencher refêrencia -->
          <div class="form-step">
            <app-circle-number stepNumber="1"></app-circle-number>

            <mat-form-field appearance="outline">
              <mat-label>{{
                "report.report-name-reference" | translate
                }}</mat-label>

              <input matInput formControlName="referenceForm" name="referenceForm" type="text" />
            </mat-form-field>
          </div>

          <!-- Passo para selecionar o tipo de relatório -->
          <div class="form-step">
            <app-circle-number stepNumber="2"></app-circle-number>

            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{ "report.type-label" | translate }}</mat-label>

              <mat-select formControlName="reportTypeForm" name="reportTypeForm"
                (selectionChange)="showReportForm(selectReports.value)" #selectReports>
                <mat-option value="alerts">{{
                  "report.general-alerts-label" | translate
                  }}</mat-option>
                <mat-option value="individualConsumption">{{
                  "report.consumption-label" | translate
                  }}</mat-option>
                <mat-option value="gatewayConsumption">{{
                  "report.gateway-consumption-label" | translate
                  }}</mat-option>
                <mat-option value="events">{{
                  "report.last-events-label" | translate
                  }}</mat-option>
                <mat-option value="status">{{
                  "report.individual-status-label" | translate
                  }}</mat-option>
                <mat-option value="lastTransmissions">{{
                  "report.last-transmission-label" | translate
                  }}</mat-option>
                <mat-option value="weather">{{
                  "report.weather-telemetry-report-label" | translate
                  }}</mat-option>
                <mat-option value="commands">{{
                  "report.commands-label" | translate
                  }}</mat-option>
                <mat-option value="installationsList">{{
                  "report.installations-label" | translate
                  }}</mat-option>
                <mat-option value="noTransmission">{{
                  "report.no-transmission-devices-label" | translate
                  }}</mat-option>
                <mat-option value="installationRecord">{{
                  "report.installation-logs-label" | translate
                  }}</mat-option>
                <mat-option value="latestData">{{
                  "report.last-data-label" | translate
                  }}</mat-option>
                <mat-option value="systemAlerts">{{
                  "report.system-alerts-label" | translate
                  }}</mat-option>
                <mat-option value="firstOnFirstOff" *ngIf="isIluminaSP">{{
                  "report.first-on-first-off-label" | translate
                  }}</mat-option>
                <mat-option value="possibleStatus" *ngIf="isIluminaSP">{{
                  "report.possible-status-label" | translate
                  }}</mat-option>
                <!-- <mat-option value="networkConsumption">{{"report.network-consumption-label" | translate}}</mat-option>
                <mat-option value="userLogs">{{"report.user-logs-label" | translate}}</mat-option>
                <mat-option value="rele">{{"report.rele-label" | translate}}</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Caso onde é necessário escolher uma instalação -->
          <!-- Relatórios de Consumo, Eventos, Meteorológico -->
          <div *ngIf="
              flagsGenForm.individualConsumption ||
              flagsGenForm.events ||
              flagsGenForm.status ||
              flagsGenForm.weather
            ">
            <!-- Campo para buscar a instalação -->
            <div class="form-step">
              <app-circle-number stepNumber="3"></app-circle-number>

              <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>{{
                  "report.search-installation-label" | translate
                  }}</mat-label>

                <input (keydown.enter)="searchInstallation()" matInput formControlName="installationForm"
                  name="installationForm" type="text" />

                <button matSuffix mat-icon-button aria-label="Clear" class="component-search-icon-button"
                  (click)="searchInstallation()">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <p *ngIf="!isLoading && installations.length === 0" class="text-danger">
              {{ "report.select_an_installation-label" | translate }}
            </p>
            <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>

            <!-- Campo para selecionar uma das instalações -->
            <div class="form-step" *ngIf="isThereInstallationListed">
              <app-circle-number stepNumber="4"></app-circle-number>

              <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>{{
                  "report.select_an_installation-label" | translate
                  }}</mat-label>

                <mat-select formControlName="selectedInstallationForm" (selectionChange)="
                    installationSelected(selectInstallation.value)
                  " #selectInstallation>
                  <mat-option *ngFor="let installation of installations" [value]="installation">
                    {{ installation.reference }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Campo para realizar a busca de concentradores -->
          <div class="form-step" *ngIf="flagsGenForm.gatewayConsumption">
            <div class="circle-number"> 3 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-gateway" | translate}}</mat-label>
              <input matInput formControlName="gateway" #referenceGatewayCreate>
              <mat-icon class="component-search-icon" (click)="searchGateway(referenceGatewayCreate.value)">
                search
              </mat-icon>
            </mat-form-field>
          </div>
          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="loadingGateway" mode="indeterminate"></mat-progress-bar>

          <!-- Campo que contém a lista de concentradores filtrados -->
          <div class="form-step" *ngIf="gatewayList.length > 0">
            <div class="circle-number-ready"> 3.1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-select-gateway" | translate}} </mat-label>
              <mat-select formControlName="gatewaySelected" (selectionChange)="gatewaySelected(selectedGateway.value)"
                #selectedGateway>
                <mat-option *ngFor="let gateway of gatewayList" [value]="gateway">
                  {{gateway.reference}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Caso onde é necessário escolher mais de uma instalação -->
          <!-- Relatório de Alertas -->
          <!-- <div *ngIf="flagsGenForm.alerts"> -->
          <!-- Passo de preencher multipla instalação -->
          <!-- <div class="form-step">
              <app-circle-number stepNumber="3"></app-circle-number>

              <mat-form-field appearance="outline">
                <mat-label>{{
                  "report.search-installation-label" | translate
                }}</mat-label>

                <input
                  (keydown.enter)="searchMultiInstallation()"
                  matInput
                  formControlName="multiInstallationForm"
                  name="multiInstallationForm"
                  type="text"
                />

                <button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  class="component-search-icon-button"
                  (click)="searchMultiInstallation()"
                >
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div> -->

          <!-- <p *ngIf="multiInstallations.length === 0" class="text-danger">
              {{ "report.select_an_installation-label" | translate }}
            </p>
            <p *ngIf="multiInstallations.length === 1">
              {{ multiInstallations.length }}
              {{ "report.one_installation_selected-label" | translate }}
            </p>
            <p *ngIf="multiInstallations.length > 1">
              {{ multiInstallations.length }}
              {{ "report.more_installations_selected-label" | translate }}
            </p>

             Passo para selecionar multiplas instalações
            <button
              *ngIf="multiInstallations.length > 0"
              (click)="searchMultiInstallation()"
              class="btn btn-primary mb-3"
            >
              Instalações selecionadas
            </button>
          </div> -->

          <!-- Caso onde é necessário escolher range de data -->
          <!-- Relatório de Comandos, Consumo, Eventos, Status e Meteorológico -->
          <div class="form-step" *ngIf="
              flagsGenForm.commands ||
              flagsGenForm.individualConsumption ||
              flagsGenForm.events ||
              flagsGenForm.status ||
              flagsGenForm.weather ||
              flagsGenForm.alerts ||
              flagsGenForm.systemAlerts ||
              flagsGenForm.gatewayConsumption
            ">
            <app-circle-number stepNumber="3" *ngIf="flagsGenForm.commands; else noCommandReport"></app-circle-number>

            <ng-template #noCommandReport>
              <app-circle-number stepNumber="5"
                *ngIf="isThereInstallationListed; else noInstallations"></app-circle-number>

              <ng-template #noInstallations>
                <app-circle-number stepNumber="4"></app-circle-number>
              </ng-template>
            </ng-template>

            <mat-form-field appearance="outline">
              <mat-label>{{ "report.date-range-label" | translate }}</mat-label>

              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="startDateForm" name="startDateForm" placeholder="Start date" />
                <input matEndDate formControlName="endDateForm" name="endDateForm" placeholder="End date" />
              </mat-date-range-input>

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <!-- Relatório de Sem Transmissão -->
          <div *ngIf="flagsGenForm.noTransmission">
            <!-- Passo de preencher horas sem transmissão do relatório de sem transmissão -->
            <div class="form-step">
              <app-circle-number stepNumber="3"></app-circle-number>

              <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>{{
                  "report.hours-without-transmission" | translate
                  }}</mat-label>

                <input type="number" (keyup)="isHourStepConcluded()" formControlName="hoursForm" matInput />
              </mat-form-field>
            </div>

            <!-- erro para campo de sem transmissão -->
            <div class="error-box" *ngIf="isHourError">
              <p class="text-danger p-2">
                {{ "report.please-enter-numbers-only" | translate }}
              </p>
            </div>
          </div>

          <!-- Relatório de Primeira mensagem de ligado e primeira mensagem de desligado -->
          <div *ngIf="flagsGenForm.firstOnFirstOff">
            <div class="form-step">
              <app-circle-number stepNumber="3"></app-circle-number>
              <mat-form-field appearance="outline">
                <mat-label>{{ "report.date-label" | translate }}</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="startDateForm" readonly="true" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker">
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker> </mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="flagsGenForm.possibleStatus">
            <div class="form-step">
              <app-circle-number stepNumber="3"></app-circle-number>
              <mat-form-field appearance="outline">
                <mat-label>{{ "report.date-label" | translate }}</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="startDateForm" readonly="true" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker">
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker> </mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <!-- Botão para reiniciar o formulário -->
          <div class="reset-form align-self-end">
            <button mat-stroked-button (click)="resetGenerationForm()">
              {{ "report.reset-form-label" | translate }}
            </button>
          </div>
          <!-- Botão para gerar relatório -->
          <button class="btn btn-success mb-2 w-100" (click)="generateReport()" [disabled]="!enableGenerateButton()">
            {{ "report.generate-report-label" | translate }}
          </button>
        </div>

        <!-- Formulário para filtro de relatórios -->
        <div class="form-fields-box" [ngClass]="!filterReportBox ? 'closed' : ''" [formGroup]="filterForm">
          <h2 (click)="filterReportBoxOpen()">
            {{ "report.filter-label" | translate }}
          </h2>
          <hr [ngClass]="!filterReportBox ? 'hr-open' : ''" />

          <!-- Passo de preencher refêrencia -->
          <div class="form-step">
            <app-circle-number stepNumber="1"></app-circle-number>

            <mat-form-field appearance="outline">
              <mat-label>{{
                "report.report-name-reference" | translate
                }}</mat-label>

              <input matInput formControlName="referenceFilterForm" name="referenceFilterForm" type="text" />
            </mat-form-field>
          </div>

          <!-- Passo para selecionar o tipo de relatório -->
          <div class="form-step">
            <app-circle-number stepNumber="2"></app-circle-number>

            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{ "report.type-label" | translate }}</mat-label>

              <mat-select formControlName="reportTypeFilterForm" name="reportTypeFilterForm">
                <mat-option value="GENERAL_ALERTS">{{
                  "report.general-alerts-label" | translate
                  }}</mat-option>
                <mat-option value="GATEWAY_CONSUMPTION">{{
                  "report.gateway-consumption-label" | translate
                  }}</mat-option>
                <mat-option value="DEVICE_LAST_EVENTS">{{
                  "report.last-events-label" | translate
                  }}</mat-option>
                <mat-option value="INDIVIDUAL_STATUS">{{
                  "report.individual-status-label" | translate
                  }}</mat-option>
                <mat-option value="LAST_TRANSMISSION">{{
                  "report.last-transmission-label" | translate
                  }}</mat-option>
                <!-- <mat-option value="WEATHER_TELEMETRY">{{'weather_telemetry-label' | translate}}</mat-option> -->
                <mat-option value="COMMANDS">{{
                  "report.commands-label" | translate
                  }}</mat-option>
                <mat-option value="INSTALLATIONS">{{
                  "report.installations-label" | translate
                  }}</mat-option>
                <mat-option value="FIRST_ON_FIRST_OFF">{{
                  "report.first-on-first-off-label" | translate
                  }}</mat-option>
                <mat-option value="POSSIBLE_STATUS">{{
                  "report.possible-status-label" | translate
                  }}</mat-option>
                <mat-option value="LAST_DATA">{{
                  "report.last-data-label" | translate
                  }}</mat-option>
                <mat-option value="NO_TRANSMISSION_DEVICES">{{
                  "report.no-transmission-devices-label" | translate
                  }}</mat-option>
                <mat-option value="INSTALLATION_LOGS">{{
                  "report.installation-logs-label" | translate
                  }}</mat-option>
                <!-- <mat-option value="networkConsumption">{{"report.network-consumption-label" | translate}}</mat-option>
                <mat-option value="userLogs">{{"report.user-logs-label" | translate}}</mat-option>
                <mat-option value="rele">{{"report.rele-label" | translate}}</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Passo de preencher usuário -->
          <div class="form-step">
            <app-circle-number stepNumber="3"></app-circle-number>

            <mat-form-field appearance="outline">
              <mat-label>{{ "report.user-label" | translate }}</mat-label>

              <input matInput formControlName="userFilter" name="userFilter" type="text" />
            </mat-form-field>
          </div>

          <!-- Botão para reiniciar o formulário -->
          <div class="reset-form align-self-end">
            <button mat-stroked-button (click)="resetFilterForm()">
              {{ "report.reset-form-label" | translate }}
            </button>
          </div>

          <!-- Botão para filtrar os relatórios -->
          <button class="btn btn-success mb-2 w-100" *ngIf="!filterLoading" (click)="filterReport(null)">
            {{ "report.filter-label" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Table Div -->
    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <!-- Sessão tabela -->
      <app-table-header [itensNumber]="paginationProperties.count" [totalNumber]="paginationProperties.total"
        [hasCSV]="false" [hasPDF]="false"></app-table-header>

      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Reference Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "report.reference-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let report">{{ report.reference }}</td>
          </ng-container>

          <!-- Generated by Column -->
          <ng-container matColumnDef="requestedBy">
            <th mat-header-cell *matHeaderCellDef>
              {{ "report.generator-by-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
              {{ report.requestedBy }}
            </td>
          </ng-container>

          <!-- report type Column -->
          <ng-container matColumnDef="reportType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "report.type-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let report">{{ getTranslationKey(report) | translate }}</td>
          </ng-container>

          <!-- satus Column -->
          <ng-container matColumnDef="ready">
            <th mat-header-cell *matHeaderCellDef>
              {{ "report.individual-status-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let report">{{ report.ready }}</td>
          </ng-container>

          <!-- Generation date Column -->
          <ng-container matColumnDef="generationDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ "report.generator-date-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
              <span matTooltip="{{ 'time-zone-information-label' | translate }}" matTooltipPosition="above">
                {{ formatDate(report.generationDate) }}
              </span>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{ "report.action-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
              <!-- Ícone de download -->
              <!-- Caso o relatório tenha sido gerado -->
              <div [ngSwitch]="report.ready">
                <ng-container *ngSwitchCase="1">
                  <mat-icon id="btnAction" class="component-svg-icon m-1" svgIcon="bx-download"
                    (click)="downloadReport(report.id)" title="Baixar Relatório">
                  </mat-icon>
                  <mat-icon (click)="deleteReport(report.id)" class="component-svg-icon m-1" *ngIf="report.ready == 1;"
                    id="btnDelete" svgIcon="bx-delete" title="Deletar Relatório">
                  </mat-icon>
                </ng-container>
                <!-- Caso o relatório esteja sendo gerado -->
                <ng-container *ngSwitchCase="0">
                  <mat-icon (click)="deleteReport(report.id)" svgIcon="bx-cancel-reports" title="Cancelar Relatório">
                  </mat-icon>
                  <p>{{ "report.download-unavailable-label" | translate }}</p>
                </ng-container>
                <!-- Caso o relatório tenha quebrado -->
                <ng-container *ngSwitchCase="3">
                  <mat-icon (click)="deleteReport(report.id)" svgIcon="bx-cancel-reports" title="Cancelar Relatório">
                  </mat-icon>
                  <p>{{ "report.failed-report-generation" | translate}}</p>
                </ng-container>
              </div>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de relatórios" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
