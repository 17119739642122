<!-- container principal -->
<div class="component-main-container">
  <!-- componentização do header -->
  <div class="header">
    <app-page-header
      parentTitle="{{ 'equipment-type.equipment-type-header' | translate }}"
      pageTitle="{{ 'equipment-type.equipment-type-label' | translate }}"
    ></app-page-header>
  </div>
  <!-- faz a tela se comportar em linha -->
  <div class="row">
    <!-- distribui a dimensão da tela -->
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <!-- div armazenadora das box de relatório -->
      <div class="component-forms-box">
        <!-- componentização da box de seleção do tipo registro -->
        <app-register-form></app-register-form>

        <!-- box de registro de equipamento -->
        <div
          class="form-fields-box"
          [ngClass]="!registerEquipmentType || editorController ? 'closed' : ''"
          [formGroup]="equipmentTypeCreateForm"
        >
          <h2 (click)="registerEquipmentTypeOpen()">
            {{ "equipment-type.create-equipment-type-label" | translate }}
          </h2>
          <hr [ngClass]="!registerEquipmentType ? 'hr-open' : ''" />

          <!-- mat responsável por armazenar o formulario de informações, hardware e firmware e comandos -->
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <!-- formulário de informações -->
            <mat-tab
              label="{{ 'equipment-type.information-label' | translate }}"
            >
              <section>
                <mat-checkbox (change)="isGateway($event.checked)">{{
                  "equipment-type.is-gateway" | translate
                }}</mat-checkbox>
              </section>

              <div class="form-step">
                <div class="circle-number">1</div>
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "equipment-type.release-data-label" | translate
                  }}</mat-label>
                  <input matInput type="date" formControlName="releaseDate" />
                </mat-form-field>
              </div>

              <div class="form-step">
                <div class="circle-number">2</div>
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "equipment-type.reference-label" | translate
                  }}</mat-label>
                  <input matInput formControlName="reference" />
                </mat-form-field>
              </div>

              <div class="form-step version">
                <div class="circle-number">3</div>
                <mat-form-field
                  appearance="outline"
                  title="{{ 'equipment-type.version-major' | translate }}"
                >
                  <mat-label>{{
                    "equipment-type.version-major" | translate
                  }}</mat-label>
                  <input matInput type="number" formControlName="major" />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  title="{{ 'equipment-type.version-minor' | translate }}"
                >
                  <mat-label>{{
                    "equipment-type.version-minor" | translate
                  }}</mat-label>
                  <input matInput type="number" formControlName="minor" />
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  title="{{ 'equipment-type.version-revision' | translate }}"
                >
                  <mat-label>{{
                    "equipment-type.version-revision" | translate
                  }}</mat-label>
                  <input matInput type="number" formControlName="revision" />
                </mat-form-field>
              </div>

              <div class="form-step">
                <div class="circle-number">4</div>
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "equipment-type.description-label" | translate
                  }}</mat-label>
                  <textarea matInput formControlName="description"></textarea>
                </mat-form-field>
              </div>

              <button
                title="{{ 'equipment-type.save-btn' | translate }}"
                class="btn btn-success"
                *ngIf="!equipmentTypeCreateLoading"
                (click)="createEquipmentType()"
                style="width: 100%"
              >
                {{ "equipment-type.save-btn" | translate }}
              </button>
              <mat-spinner
                *ngIf="equipmentTypeCreateLoading"
                [diameter]="30"
              ></mat-spinner>
            </mat-tab>

            <!-- formulário de hardware e firmware -->
            <mat-tab
              label="{{
                'equipment-type.hardware-and-firmware-label' | translate
              }}"
            >
              <div class="form-step">
                <div class="circle-number">1</div>
                <mat-form-field appearance="outline">
                  <mat-label> Hardwares </mat-label>
                  <mat-select formControlName="hardware">
                    <mat-option
                      *ngFor="let hardwares of hardwaresList"
                      [value]="hardwares.id"
                      (click)="filterCompatibleFirmware(hardwares.id)"
                    >
                      {{ hardwares.name }} - {{ hardwares.major }}.{{
                        hardwares.minor
                      }}.{{ hardwares.revision }}
                    </mat-option>
                    <mat-option *ngIf="hardwaresList.length === 0">
                      {{
                        "equipment-type.there-not-compatible-hardware"
                          | translate
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-step">
                <div class="circle-number">2</div>
                <mat-form-field
                  appearance="outline"
                  class="response-properties"
                >
                  <mat-label>{{
                    "equipment-type.compatible-firmware" | translate
                  }}</mat-label>
                  <mat-select
                    formControlName="compatiblefirmware"
                    *ngIf="compatiblefirmwaresList.length > 0"
                    multiple
                  >
                    <mat-option
                      *ngFor="let firmware of compatiblefirmwaresList"
                      [value]="firmware.id"
                    >
                      {{ firmware.name }} ({{ firmware.major }}.{{
                        firmware.minor
                      }}.{{ firmware.revision }})
                    </mat-option>
                  </mat-select>
                  <mat-select *ngIf="compatiblefirmwaresList.length === 0">
                    <mat-option disabled
                      >{{
                        "equipment-type.there-not-compatible-firmware"
                          | translate
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- formulário de criação de hardware -->
              <mat-accordion [formGroup]="createHardwareAndFirmwareForm">
                <mat-expansion-panel
                  (opened)="panelOpenHardware = true"
                  (closed)="panelOpenHardware = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ "equipment-type.new-hardware" | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="form-step">
                    <div class="circle-number">1</div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "equipment-type.name-label" | translate
                      }}</mat-label>
                      <input matInput formControlName="referenceHardware" />
                    </mat-form-field>
                  </div>

                  <div class="form-step">
                    <div class="circle-number">2</div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "equipment-type.release-data-label" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="date"
                        formControlName="releaseDateHardware"
                      />
                    </mat-form-field>
                  </div>

                  <div class="form-step version">
                    <div class="circle-number">4</div>
                    <mat-form-field
                      appearance="outline"
                      title="{{ 'equipment-type.version-major' | translate }}"
                    >
                      <mat-label>{{
                        "equipment-type.version-major" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="number"
                        formControlName="majorHardware"
                      />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      title="{{ 'equipment-type.version-minor' | translate }}"
                    >
                      <mat-label>{{
                        "equipment-type.version-minor" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="number"
                        formControlName="minorHardware"
                      />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      title="{{
                        'equipment-type.version-revision' | translate
                      }}"
                    >
                      <mat-label>{{
                        "equipment-type.version-revision" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="number"
                        formControlName="revisionHardware"
                      />
                    </mat-form-field>
                  </div>

                  <div class="form-step">
                    <div class="circle-number">5</div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "equipment-type.description-label" | translate
                      }}</mat-label>
                      <textarea
                        matInput
                        formControlName="descriptionHardware"
                      ></textarea>
                    </mat-form-field>
                  </div>

                  <button
                    title="Salvar"
                    class="btn btn-success"
                    (click)="createHardware()"
                    *ngIf="!hardwareCreateLoading"
                  >
                    {{ "equipment-type.save-btn" | translate }}
                  </button>
                  <mat-spinner
                    [diameter]="30"
                    *ngIf="hardwareCreateLoading"
                  ></mat-spinner>
                </mat-expansion-panel>

                <!-- formulário de criação de firmware -->
                <mat-expansion-panel
                  (opened)="panelOpenFirmware = true"
                  (closed)="panelOpenFirmware = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ "equipment-type.new-firmware" | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="form-step">
                    <div class="circle-number">1</div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "equipment-type.name-label" | translate
                      }}</mat-label>
                      <input matInput formControlName="referenceFirmware" />
                    </mat-form-field>
                  </div>

                  <div class="form-step">
                    <div class="circle-number">2</div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "equipment-type.release-data" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="date"
                        formControlName="releaseDateFirmware"
                      />
                    </mat-form-field>
                  </div>

                  <div class="form-step version">
                    <div class="circle-number">3</div>
                    <mat-form-field
                      appearance="outline"
                      title="{{ 'equipment-type.version-major' | translate }}"
                    >
                      <mat-label>{{
                        "equipment-type.version-major" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="number"
                        formControlName="majorFirmware"
                      />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      title="{{ 'equipment-type.version-minor' | translate }}"
                    >
                      <mat-label>{{
                        "equipment-type.version-minor" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="number"
                        formControlName="minorFirmware"
                      />
                    </mat-form-field>
                    <mat-form-field
                      appearance="outline"
                      title="{{
                        'equipment-type.version-revision' | translate
                      }}"
                    >
                      <mat-label>{{
                        "equipment-type.version-revision" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="number"
                        formControlName="revisionFirmware"
                      />
                    </mat-form-field>
                  </div>

                  <div class="form-step">
                    <div class="circle-number">4</div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "equipment-type.description-label" | translate
                      }}</mat-label>
                      <textarea
                        matInput
                        formControlName="descriptionFirmware"
                      ></textarea>
                    </mat-form-field>
                  </div>

                  <button
                    title="Salvar"
                    class="btn btn-success"
                    (click)="createFirmware()"
                    *ngIf="!firmwareCreateLoading"
                  >
                    {{ "equipment-type.save-btn" | translate }}
                  </button>
                  <mat-spinner
                    [diameter]="30"
                    *ngIf="firmwareCreateLoading"
                  ></mat-spinner>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-tab>

            <!-- formulário de comandos -->
            <mat-tab
              label="{{ 'equipment-type.commands-label' | translate }}"
              *ngIf="!isGatewaySelected"
            >
              <div class="form-step">
                <div class="checkbox">
                  <section>
                    <mat-checkbox
                      formControlName="hasClockSync"
                      (change)="hasClockSyncChecked($event.checked)"
                    >
                      {{ "equipment-type.has-clock-label" | translate }}
                    </mat-checkbox>
                  </section>
                  <section>
                    <mat-checkbox
                      formControlName="hasActuation"
                      (change)="hasActuationChecked($event.checked)"
                    >
                      {{ "equipment-type.has-actuation-label" | translate }}
                    </mat-checkbox>
                  </section>
                </div>
                <div class="checkbox">
                  <section>
                    <mat-checkbox
                      formControlName="hasPowerQuality"
                      (change)="hasPowerQualityChecked($event.checked)"
                    >
                      {{
                        "equipment-type.has-power-quality-label" | translate
                      }}</mat-checkbox
                    >
                  </section>
                  <section>
                    <mat-checkbox
                      formControlName="oldCrc"
                      (change)="oldCrcChecked($event.checked)"
                    >
                      {{
                        "equipment-type.has-old-crc-label" | translate
                      }}</mat-checkbox
                    >
                  </section>
                </div>
              </div>

              <!-- Título para adição de comandos -->
              <div class="form-step">
                <div class="checkbox">
                  <span
                    ><b
                      >{{ "equipment-type.add-commands-title" | translate }}:</b
                    ></span
                  >
                </div>
              </div>

              <!-- Botão para adicionar comandos de consulta -->
              <div class="form-step">
                <button
                  mat-fab
                  extended
                  color="primary"
                  aria-label="Criar comando"
                  title="{{
                    'equipment-type.create-command-label' | translate
                  }}"
                  (click)="openModalQueryCommands()"
                  style="margin: 5px"
                >
                  <mat-icon>control_point</mat-icon>
                  {{ "equipment-type.query-commands" | translate }}
                </button>

                <!-- Botão para adicionar comandos de configuração -->
                <button
                  mat-fab
                  extended
                  color="primary"
                  aria-label="Criar comando"
                  title="{{
                    'equipment-type.create-command-label' | translate
                  }}"
                  (click)="openModalConfigCommands()"
                >
                  <mat-icon>control_point</mat-icon
                  >{{ "equipment-type.config-commands" | translate }}
                </button>
              </div>

              <!-- Aba de adição dos comandos de consulta -->
              <div
                class="commands"
                *ngIf="queryCommands.length > 0 && !editorController"
              >
                <h2>{{ "equipment-type.query-commands" | translate }}</h2>
                <h4>
                  {{ "equipment-type.query-commands-sub-title" | translate }}
                </h4>
                <mat-chip-set
                  class="example-chip"
                  *ngFor="let query of queryCommands; let i = index"
                >
                  <mat-chip class="example-box" cdkDrag>
                    <span> {{ query?.responseProperties }} </span>
                    <span>
                      (
                      <span *ngIf="query?.type === 'STATUS'">
                        {{ "equipment-type.queryCommands-status" | translate }}
                      </span>
                      <span *ngIf="query?.type === 'PARAMETERS'">
                        {{
                          "equipment-type.queryCommands-parameters" | translate
                        }}
                      </span>
                      <span *ngIf="query?.type === 'DIAGNOSTIC'">
                        {{
                          "equipment-type.queryCommands-diagnostic" | translate
                        }}
                      </span>
                      <span *ngIf="query?.type === 'MEASUREMENT'">
                        {{
                          "equipment-type.queryCommands-measurement" | translate
                        }}
                      </span>
                      <span *ngIf="query?.type === 'LATEST_MEASUREMENT'">
                        {{
                          "equipment-type.queryCommands-latest-measurement"
                            | translate
                        }}</span
                      >
                      <span *ngIf="query?.type === 'VERSION'">
                        {{ "equipment-type.queryCommands-version" | translate }}
                      </span>
                      )
                    </span>
                    <button (click)="editQueryCommands(i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        style="cursor: pointer"
                        fill="currentColor"
                        class="bi bi-pencil"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                        />
                      </svg>
                    </button>
                    <button (click)="clearQueryCommandsByIndex(i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                        style="cursor: pointer"
                      >
                        <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                        />
                      </svg>
                    </button>
                  </mat-chip>
                </mat-chip-set>
              </div>

              <!-- Aba de adição dos comandos de configuração -->
              <div
                class="commands"
                *ngIf="configCommands.length > 0 && !editorController"
              >
                <h2>{{ "equipment-type.config-commands" | translate }}</h2>
                <h4>
                  {{ "equipment-type.config-commands-sub-title" | translate }}
                </h4>
                <mat-chip-set
                  class="example-chip"
                  *ngFor="let commands of configCommands; let i = index"
                >
                  <mat-chip class="example-box">
                    <span>{{ commands?.reference }}</span>
                    <span>
                      (
                      <span *ngIf="commands?.maxValue || commands?.minValue">
                        {{ commands?.minValue }}, {{ commands?.maxValue }}
                      </span>
                      <span *ngIf="commands?.choices">
                        <span *ngFor="let choice of commands.choices">
                          {{ choice?.description }}
                        </span>
                      </span>
                      )
                    </span>
                    <button (click)="editConfigCommands(i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        style="cursor: pointer"
                        fill="currentColor"
                        class="bi bi-pencil"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                        />
                      </svg>
                    </button>
                    <button (click)="clearConfigCommandsByIndex(i)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                        style="cursor: pointer"
                      >
                        <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                        />
                      </svg>
                    </button>
                  </mat-chip>
                </mat-chip-set>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- formulário de filtro -->
        <div
          class="form-fields-box"
          [ngClass]="!filterEquipmentType ? 'closed' : ''"
          [formGroup]="equipmentTypeFilterForm"
        >
          <h2 (click)="filterEquipmentTypeOpen()">
            {{ "equipment-type.filter-equipment-type" | translate }}
          </h2>
          <hr [ngClass]="!filterEquipmentType ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "equipment-type.name-label" | translate
              }}</mat-label>
              <input matInput formControlName="reference" />
            </mat-form-field>
          </div>

          <div class="form-step version">
            <div class="circle-number">2</div>
            <mat-form-field
              appearance="outline"
              title="{{ 'equipment-type.version-major' | translate }}"
            >
              <mat-label>{{
                "equipment-type.version-major" | translate
              }}</mat-label>
              <input matInput type="number" formControlName="major" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              title="{{ 'equipment-type.version-minor' | translate }}"
            >
              <mat-label>{{
                "equipment-type.version-minor" | translate
              }}</mat-label>
              <input matInput type="number" formControlName="minor" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              title="{{ 'equipment-type.version-revision' | translate }}"
            >
              <mat-label>{{
                "equipment-type.version-revision" | translate
              }}</mat-label>
              <input matInput type="number" formControlName="revision" />
            </mat-form-field>
          </div>

          <button
            title="Filtrar"
            class="btn btn-success"
            (click)="equipmentTypeFilter(null)"
            *ngIf="!equipmentTypeLoading"
          >
            {{ "equipment-type.filter-btn" | translate }}
          </button>
          <mat-spinner
            [diameter]="30"
            *ngIf="equipmentTypeLoading"
          ></mat-spinner>
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <app-table-header
        *ngIf="length > 0"
        [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total"
        [hasCSV]="true"
        [hasPDF]="true"
        [loadingData$]="fileLoading"
        (downloadCSV)="generateFileData(null, 'CSV')"
        (downloadPDF)="generateFileData(null, 'PDF')"
      >
      </app-table-header>

      <!-- Sessão tabela -->
      <div class="table-div mt-2 mt-lg-3 mt-xl-3" *ngIf="ELEMENT_DATA.length">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- reference Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipment-type.reference-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
          </ng-container>

          <!-- version Column -->
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipment-type.version-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.major }}.{{ element.minor }}.{{ element.revision }}
            </td>
          </ng-container>

          <!-- release data Column -->
          <ng-container matColumnDef="release-data">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipment-type.release-data-label" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.releaseDate }}
            </td>
          </ng-container>

          <!-- Coluna com botões de edição/visualização -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipment-type.actions" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              on-mouseover="element.view = true"
              on-mouseleave="element.view = false"
            >
              <!-- Botão de edição -->
              <button
                (click)="openSidebarUpdate(false, element)"
                [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view"
              >
                <mat-icon
                  class="component-svg-icon"
                  svgIcon="bx-edit"
                ></mat-icon>
              </button>
              <!-- Botão de visualização -->
              <button
                (click)="openSidebarUpdate(true, element)"
                [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view"
              >
                <mat-icon
                  class="component-svg-icon"
                  svgIcon="bx-show-white"
                ></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- Paginator -->
        <mat-paginator
          #paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          (page)="pageChanged($event)"
          aria-label="Selecione as páginas de Tipos de equipamentos"
          class="mat-paginator-sticky"
        >
        </mat-paginator>
      </div>
    </div>

    <!-- Modal de edição de tipos de equipamentos -->
    <div
      class="sidebar-edit"
      *ngIf="editorController"
      [formGroup]="updateEquipmentTypeForm"
    >
      <mat-icon (click)="closeSidebarUpdate()"> clear </mat-icon>
      <h2 class="title-edit">
        {{ "equipment-type.edit-equipment-type-title" | translate }}
      </h2>
      <hr />

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab label="Informações">
          <br />
          <section class="checkbox-gateway">
            <mat-checkbox
              (change)="isGatewayCkeckedUpdate($event.checked)"
              formControlName="isGateway"
            >
              {{ "equipment-type.is-gateway" | translate }}
            </mat-checkbox>
          </section>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "equipment-type.release-data-label" | translate }}
              </mat-label>
              <input matInput type="date" formControlName="releaseDate" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field
              appearance="outline"
              *ngIf="!isGatewaySelectedUpdate"
            >
              <mat-label>
                {{ "equipment-type.reference-label" | translate }}
              </mat-label>
              <input matInput formControlName="reference" [readonly]="true" />
            </mat-form-field>
          </div>

          <div class="version">
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "equipment-type.version-major" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                formControlName="major"
                [readonly]="true"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "equipment-type.version-minor" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                formControlName="minor"
                [readonly]="true"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "equipment-type.version-revision" | translate }}
              </mat-label>
              <input
                matInput
                type="number"
                formControlName="revision"
                [readonly]="true"
              />
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ "equipment-type.description-label" | translate }}
            </mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
          <button
            title="Salvar"
            *ngIf="!equipmentTypeLoading"
            class="btn btn-success"
            (click)="updateEquipmentType()"
          >
            {{ "equipment-type.save-btn" | translate }}
          </button>
          <mat-spinner
            *ngIf="equipmentTypeLoading"
            [diameter]="30"
          ></mat-spinner>
        </mat-tab>

        <mat-tab label="Hadwares e Firmwares">
          <br />
          <mat-form-field appearance="outline">
            <mat-label> Hardware </mat-label>
            <mat-select #value formControlName="hardwareSelected">
              <!-- Lista todos os hardwares passa o id do hardware já cadastrado -->
              <mat-option
                *ngFor="let hardwares of hardwaresList"
                [value]="hardwares.id"
                (click)="filterCompatibleFirmware(hardwares.id)"
              >
                {{ hardwares.name }} - {{ hardwares.major }}.{{
                  hardwares.minor
                }}.{{ hardwares.revision }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field appearance="outline">
            <mat-label>
              {{ "equipment-type.compatible-firmware" | translate }}
            </mat-label>
            <mat-select multiple>
              <!-- Método será chamado quando todos os firmwares forem selecionadas -->
              <mat-option
                (click)="checkAllCompatiblesFirmwares(selectAllFirmwares)"
                #selectAllFirmwares
              >
                Todos</mat-option
              >
              <!-- Método é chamado sempre que houver uma alteração nos firmwares selecionados -->
              <mat-checkbox
                color="primary"
                (change)="checkFirmwareUpdate(firmware)"
                [checked]="firmware.checked"
                *ngFor="let firmware of compatiblefirmwaresList"
                [id]="firmware.id"
              >
                {{ firmware.name }} ({{ firmware.major }}.{{
                  firmware.minor
                }}.{{ firmware.revision }})
              </mat-checkbox>
            </mat-select>
          </mat-form-field>

          <br />
          <!-- formulário de criação de hardware -->
          <mat-accordion [formGroup]="createHardwareAndFirmwareForm">
            <mat-expansion-panel
              (opened)="panelOpenHardware = true"
              (closed)="panelOpenHardware = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "equipment-type.new-hardware" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="container-flex">
                <div class="item">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "equipment-type.name-label" | translate
                    }}</mat-label>
                    <input matInput formControlName="referenceHardware" />
                  </mat-form-field>
                </div>

                <div class="item">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "equipment-type.release-data-label" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="date"
                      formControlName="releaseDateHardware"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="version">
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "equipment-type.version-major" | translate }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="majorHardware"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "equipment-type.version-minor" | translate }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="minorHardware"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "equipment-type.version-revision" | translate }}
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="revisionHardware"
                  />
                </mat-form-field>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "equipment-type.description-label" | translate }}
                </mat-label>
                <textarea
                  matInput
                  formControlName="descriptionHardware"
                ></textarea>
              </mat-form-field>
              <br />

              <button
                title="Salvar"
                class="btn btn-success"
                id="button-new-hardware"
                (click)="createHardware()"
                *ngIf="!hardwareCreateLoading"
              >
                {{ "equipment-type.save-btn" | translate }}
              </button>
              <mat-spinner
                [diameter]="30"
                *ngIf="hardwareCreateLoading"
              ></mat-spinner>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="panelOpenFirmware = true"
              (closed)="panelOpenFirmware = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "equipment-type.new-firmware" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="container-flex">
                <div class="item">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "equipment-type.name-label" | translate
                    }}</mat-label>
                    <input matInput formControlName="referenceFirmware" />
                  </mat-form-field>
                </div>

                <div class="item">
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "equipment-type.release-data" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="date"
                      formControlName="releaseDateFirmware"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="version">
                <mat-form-field
                  appearance="outline"
                  title="{{ 'equipment-type.version-major' | translate }}"
                >
                  <mat-label>{{
                    "equipment-type.version-major" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="majorFirmware"
                  />
                </mat-form-field>

                <mat-form-field
                  appearance="outline"
                  title="{{ 'equipment-type.version-minor' | translate }}"
                >
                  <mat-label>{{
                    "equipment-type.version-minor" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="minorFirmware"
                  />
                </mat-form-field>

                <mat-form-field
                  appearance="outline"
                  title="{{ 'equipment-type.version-revision' | translate }}"
                >
                  <mat-label>{{
                    "equipment-type.version-revision" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="revisionFirmware"
                  />
                </mat-form-field>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>{{
                  "equipment-type.description-label" | translate
                }}</mat-label>
                <textarea
                  matInput
                  formControlName="descriptionFirmware"
                ></textarea>
              </mat-form-field>

              <button
                title="Salvar"
                class="btn btn-success"
                id="button-new-firmware"
                (click)="createFirmware()"
                *ngIf="!firmwareCreateLoading"
              >
                {{ "equipment-type.save-btn" | translate }}
              </button>
              <mat-spinner
                [diameter]="30"
                *ngIf="firmwareCreateLoading"
              ></mat-spinner>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>

        <!-- Campos dos comandos -->
        <mat-tab
          label="Comandos"
          *ngIf="!updateEquipmentTypeForm.value.isGateway"
        >
          <br />
          <div class="checkbox">
            <section class="checkbox-commands">
              <mat-checkbox
                (change)="hasClockSyncChecked($event.checked)"
                formControlName="hasClockSync"
              >
                {{ "equipment-type.has-clock-label" | translate }}
              </mat-checkbox>
            </section>
            <section class="checkbox-commands">
              <mat-checkbox
                (change)="hasActuationChecked($event.checked)"
                formControlName="hasActuation"
              >
                {{ "equipment-type.has-actuation-label" | translate }}
              </mat-checkbox>
            </section>
          </div>

          <div class="checkbox">
            <section class="checkbox-commands">
              <mat-checkbox
                (change)="hasPowerQualityChecked($event.checked)"
                formControlName="hasPowerQuality"
              >
                {{ "equipment-type.has-power-quality-label" | translate }}
              </mat-checkbox>
            </section>

            <section class="checkbox-commands">
              <mat-checkbox
                (change)="oldCrcChecked($event.checked)"
                formControlName="oldCrc"
              >
                {{ "equipment-type.has-old-crc-label" | translate }}
              </mat-checkbox>
            </section>
          </div>
          <br />
          <mat-accordion class="example-headers-align p-0">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "equipment-type.query-commands" | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ "equipment-type.add-query-commands" | translate }}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-chip-set class="example-chip">
                <div class="commands" *ngIf="queryCommands.length > 0">
                  <mat-chip-set
                    class="example-chip"
                    *ngFor="let query of queryCommands; let i = index"
                  >
                    <mat-chip
                      class="example-box"
                      *ngIf="query.responseProperties && query.type"
                    >
                      <span> {{ query?.responseProperties }}</span>
                      <span>
                        (
                        <span *ngIf="query?.type === 'STATUS'">
                          {{
                            "equipment-type.queryCommands-status" | translate
                          }}
                        </span>
                        <span *ngIf="query?.type === 'PARAMETERS'">
                          {{
                            "equipment-type.queryCommands-parameters"
                              | translate
                          }}
                        </span>
                        <span *ngIf="query?.type === 'DIAGNOSTIC'">
                          {{
                            "equipment-type.queryCommands-diagnostic"
                              | translate
                          }}</span
                        >
                        <span *ngIf="query?.type === 'MEASUREMENT'">
                          {{
                            "equipment-type.queryCommands-measurement"
                              | translate
                          }}
                        </span>
                        <span *ngIf="query?.type === 'LATEST_MEASUREMENT'">
                          {{
                            "equipment-type.queryCommands-latest-measurement"
                              | translate
                          }}</span
                        >
                        <span *ngIf="query?.type === 'VERSION'">
                          {{
                            "equipment-type.queryCommands-version" | translate
                          }}
                        </span>
                        )
                      </span>
                      <button (click)="editQueryCommands(i)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          style="cursor: pointer"
                          fill="currentColor"
                          class="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                          />
                        </svg>
                      </button>
                      <button (click)="clearQueryCommandsByIndex(i)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                          style="cursor: pointer"
                        >
                          <path
                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                          />
                        </svg>
                      </button>
                    </mat-chip>
                  </mat-chip-set>
                </div>
              </mat-chip-set>
              <button
                mat-fab
                extended
                color="primary"
                aria-label="Criar comando"
                title="Adicionar comandos"
                (click)="openModalQueryCommands()"
                style="margin: 5px"
              >
                <mat-icon>control_point</mat-icon>
                {{ "equipment-type.add-commands-title" | translate }}
              </button>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "equipment-type.config-commands" | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ "equipment-type.add-config-commands" | translate }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-chip-set class="example-chip">
                <div class="commands" *ngIf="configCommands.length > 0">
                  <mat-chip-set
                    class="example-chip"
                    *ngFor="let commands of configCommands; let i = index"
                  >
                    <mat-chip
                      class="example-box"
                      *ngIf="
                        commands.reference &&
                        commands.targetReference &&
                        commands.type
                      "
                    >
                      <span> {{ commands?.reference }}</span>
                      <span>
                        (
                        <span *ngIf="commands?.maxValue || commands?.minValue">
                          {{ commands?.minValue }}, {{ commands?.maxValue }}
                        </span>
                        <span *ngIf="commands?.choices">
                          <span *ngFor="let choice of commands?.choices">
                            {{ choice.description }}
                          </span>
                        </span>
                        )
                      </span>
                      <button (click)="editConfigCommands(i)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          style="cursor: pointer"
                          fill="currentColor"
                          class="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                          />
                        </svg>
                      </button>
                      <button (click)="clearConfigCommandsByIndex(i)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                          style="cursor: pointer"
                        >
                          <path
                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                          />
                        </svg>
                      </button>
                    </mat-chip>
                  </mat-chip-set>
                </div>
              </mat-chip-set>

              <div class="form-step">
                <button
                  mat-fab
                  extended
                  color="primary"
                  aria-label="Criar comando"
                  title="Adicionar comandos"
                  (click)="openModalConfigCommands()"
                  style="margin: 5px"
                >
                  <mat-icon>control_point</mat-icon>
                  {{ "equipment-type.add-commands-title" | translate }}
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
