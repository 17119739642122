import { Apollo } from "apollo-angular";
import { EQUIPMENT_CREATE, FILTER_EQUIPMENTS, GET_INSTALLATIONS_HISTORY_LOGS, UPDATE_EQUIPMENTS } from "src/app/graphql/equipment.graphql";
import { Injectable } from "@angular/core";
import { FILTER_ALL_VERSIONS } from "src/app/graphql/equipment-type.graphql";

@Injectable({
  providedIn: 'root'
})

export class EquipmentsService {

  constructor(private apollo: Apollo) {
    //Verifica se uma empresa foi selecionada pelo usuário
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  //Variavel responsável por armazenar o ID da empresa selecionada
  private company: string | null;

  //Função responsável por criar equipamentos
  public createEquipment(
    company: string,
    macAddress: string,
    serialNumber: string,
    equipmentType: string
  ) {
    return this.apollo.mutate({
      mutation: EQUIPMENT_CREATE,
      variables: {
        company: company,
        macAddress: macAddress,
        serialNumber: serialNumber,
        equipmentType: equipmentType
      }
    })
  }

  //Função para filtrar os equipamentos
  public filterEquipments(
    equipmentsType: string | null,
    search: string | null,
    cursor: string | null,
    pageSize: number
  ) {
    return this.apollo.watchQuery({
      query: FILTER_EQUIPMENTS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        cursor: cursor,
        pageSize,
        search,
        equipmentTypes: [`${equipmentsType}`],
        sort_dir: "ASC",
        sort_field: "MAC_ADDRESS"
      }
    })
  }

  //Função para filtrar os equipamentos
  public filterEquipmentBySerialNumber(
    serialNumber: string,
    isInstalled: boolean | null
  ) {
    return this.apollo.watchQuery({
      query: FILTER_EQUIPMENTS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        serialNumber,
        isInstalled,
        sort_dir: "ASC",
        sort_field: "MAC_ADDRESS",
      }
    })
  }

  // Função responsável por atualizar o equipamento
  public updateEquipment(
    id: string | null,
    company: string | null,
    macAddress: string | null,
    serialNumber: string | null,
    equipmentType: any | null
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_EQUIPMENTS,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        company,
        macAddress,
        serialNumber,
        equipmentType
      }
    })
  }

  public getEquipmentTypes() {
    return this.apollo.watchQuery({
      query: FILTER_ALL_VERSIONS,
      fetchPolicy: 'network-only',
      variables: {}
    })
  }

  /** Chama a requisição do filtro de logs de histórico de instalações que o equipamento passou **/
  public getInstallationsLogs(device: string | null) {
    return this.apollo.watchQuery({
      query: GET_INSTALLATIONS_HISTORY_LOGS,
      fetchPolicy: 'network-only',
      variables: {
        device
      }
    })
  }
}