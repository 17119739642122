<div class="component-main-container">
  <!-- sessão header -->
  <div class="header">
    <app-page-header parentTitle="{{ 'equipments.equipments-parent-label' | translate }}"
      pageTitle="{{ 'equipments.equipments-page-label' | translate }}"></app-page-header>
  </div>

  <!-- div responsável por fazer com que a tela se comporte como uma linha -->
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <!-- div responsavel por armazenas as box da página -->
      <div class="component-forms-box">
        <!-- box de tipo de registro -->
        <app-register-form></app-register-form>

        <!-- box responsável pela criação de equipamentos -->
        <div class="form-fields-box" [ngClass]="!registerEquipment ? 'closed' : ''" [formGroup]="equipmentForm">
          <h2 (click)="registerEquipmentOpen()">
            {{ "equipments.register-equipments" | translate }}
          </h2>
          <hr [ngClass]="!registerEquipment ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "equipments.form-mac-adress" | translate
                }}</mat-label>
              <input matInput formControlName="macAddress" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "equipments.form-serial-number" | translate }}</mat-label>
              <input matInput type="text" formControlName="serialNumber" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "equipments.form-equipment-type" | translate }}</mat-label>
              <mat-select formControlName="equipmentType" #value>
                <mat-option appearance="outline" *ngFor="let item of equipmentTypeList" [value]="item.id">{{
                  item.reference }} ({{ item.major }}.{{ item.minor }}.{{
                  item.revision
                  }})</mat-option>
                <mat-option *ngIf="equipmentLoading"><mat-spinner [diameter]="30"></mat-spinner></mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <button title="Salvar" class="btn btn-success" *ngIf="!equipmentTypeLoading" (click)="createEquipment()">
            {{ "equipments.button-save" | translate }}
          </button>
          <mat-spinner *ngIf="equipmentTypeLoading" [diameter]="30"></mat-spinner>
        </div>

        <div class="form-fields-box" [ngClass]="!filterEquipment ? 'closed' : ''" [formGroup]="filterForm">
          <h2 (click)="filterEquipmentOpen()">
            {{ "equipments.filter-equipments" | translate }}
          </h2>
          <hr [ngClass]="!filterEquipment ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "equipments.form-keyword" | translate }}</mat-label>
              <input matInput formControlName="search" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "equipments.form-equipment-type" | translate
                }}</mat-label>
              <mat-select formControlName="equipmentTypeId">
                <mat-option appearance="outline" *ngFor="let item of equipmentTypeList" [value]="item.id">{{
                  item.reference }} ({{ item.major }}.{{ item.minor }}.{{
                  item.revision
                  }})</mat-option>
                <mat-option *ngIf="getEquipmentType">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <button *ngIf="!equipmentLoading" title="{{ 'equipments.button-filter' | translate }}" class="btn btn-success"
            (click)="filterEquipments(null)">
            {{ "equipments.button-filter" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="equipmentLoading"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="length > 0">
      <app-table-header [itensNumber]="this.paginationProperties.count" [totalNumber]="paginationProperties.total"
        [hasCSV]="true" [hasPDF]="true" (downloadCSV)="generateFileData(null, 'CSV')"
        (downloadPDF)="generateFileData(null, 'PDF')" [loadingData$]="fileLoading">
      </app-table-header>
      <!-- Sessão tabela -->
      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="macAddress">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipments.form-mac-adress" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.macAddress }}</td>
          </ng-container>

          <!-- CNPJ Column -->
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipments.form-serial-number" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.serialNumber }}
            </td>
          </ng-container>

          <!-- CNPJ Column -->
          <ng-container matColumnDef="equipmentType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipments.form-equipment-type" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.equipmentType.reference }}.{{
              element.equipmentType.major
              }}.{{ element.equipmentType.minor }}.{{
              element.equipmentType.revision
              }}
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{ "equipments.actions-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="openSidebarUpdate(element)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success mb-2 view">
                <!-- SVG do ícone de edição -->
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <button (click)="openHistoryLogsSidebar(element)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-file-white"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Equipments" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>

    <div class="sidebar-edit" *ngIf="sideBarEdit" [formGroup]="updateForm">
      <mat-icon (click)="sideBarEdit = false">clear</mat-icon>
      <h2>{{"equipments.form-edit" | translate}}</h2>

      <mat-form-field appearance="outline">
        <mat-label>{{ "equipments.form-edit-mac-adress" | translate }}</mat-label>
        <input matInput formControlName="macAddress" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "equipments.form-edit-serial-number" | translate }}</mat-label>
        <input matInput formControlName="serialNumber" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "equipments.form-edit-equipment-type" | translate }}</mat-label>
        <mat-select formControlName="equipmentTypeId">
          <mat-option appearance="outline" *ngFor="let item of equipmentTypeList" [value]="item.id">
            {{ item.reference }} ({{ item.major }}.{{ item.minor }}.{{
            item.revision
            }})
          </mat-option>
          <mat-option *ngIf="getEquipmentType"><mat-spinner [diameter]="30"></mat-spinner></mat-option>
        </mat-select>
      </mat-form-field>

      <button *ngIf="!getEquipmentType" class="btn btn-success" (click)="updateEquipment()">
        {{ "equipments.button-edit" | translate }}
      </button>
    </div>
  </div>
  <ng-template #dialogTemplate let-dialogRef="dialogRef">
    <mat-dialog-content class="mat-typography">
      <div class="dialogRef" style="text-align: center;">
        <mat-spinner *ngIf="loadingLogs" [diameter]="30"></mat-spinner>
        <span *ngIf="loadingLogs" style="font-size:20px; color: #2633d8"> {{'equipments.loading-logs-message' |
          translate}} </span>
        <h1 *ngIf="!loadingLogs" mat-dialog-title>{{'equipments.title-installation-history-logs' | translate}} </h1>
        <hr *ngIf="!loadingLogs">
        <div *ngFor="let item of installationLogsList" class="d-flex align-items-start flex-column text-center"
          style="font-size: 19px;">
          <div class="p-2">
            <strong *ngIf="!loadingLogs"> {{'equipments.start-date-time' | translate}} </strong>{{item.startDatetime}}
          </div>
          <div class="p-2">
            <strong *ngIf="!loadingLogs"> {{'equipments.installation' | translate}}: </strong>
            {{item.installation.reference}}
          </div>
          <mat-divider *ngIf="installationLogsList.length > 1"></mat-divider>
        </div>
        <strong *ngIf="logsNotFound" style="font-size:18px;"> {{'equipments.no-logs-message' | translate}} </strong>
      </div>
    </mat-dialog-content>
  </ng-template>
</div>