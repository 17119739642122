<div class="component-main-container">
  <div class="header">
    <app-page-header pageTitle="{{'manage-user.pageTitle' | translate}}"
      parentTitle="{{'manage-user.parentTitle' | translate}}"></app-page-header>
  </div>
  <!--Corpo do componente-->
  <div class="row">
    <div class="col-lg-4 col-xl-4 col-sm-12">
      <div class="component-forms-box">

        <!-- Box de criação de usuários -->
        <div class="form-fields-box" [ngClass]="!isCreateUser ? 'closed' : ''" [formGroup]="createUserForm">
          <h2 (click)="createUserBox()">
            {{ "manage-user.create-form-title" | translate }}
          </h2>
          <hr [ngClass]="!isCreateUser ? 'hr-open' : ''" />

          <!-- Campos do formulário -->
          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "manage-user.name-create-form" | translate }}
              </mat-label>
              <input matInput formControlName="firstNameCreateForm" type="text" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "manage-user.last-name-create-form" | translate
                }}</mat-label>
              <input matInput formControlName="lastNameCreateForm" type="text" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "manage-user.user-create-form" | translate }}
              </mat-label>
              <input matInput formControlName="usernameCreateForm" type="text" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">4</div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "manage-user.email-create-form" | translate }} </mat-label>
              <input matInput formControlName="emailCreateForm" type="email" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">5</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "manage-user.password-create-form" | translate }}
              </mat-label>
              <input matInput formControlName="passwordCreateForm" type="text" />
            </mat-form-field>
          </div>

          <div style="list-style-type: none;">
            <li style="color:red;" *ngIf="createUserForm.get('passwordCreateForm')?.hasError('minlength')">
              - {{ "manage-user.password-character-validator" | translate }}
            </li>
            <li class="mb-5" style="color:red;" *ngIf="createUserForm.get('passwordCreateForm')?.hasError('pattern')">
              - {{ "manage-user.password-validatior" | translate }}
            </li>
          </div>

          <!-- Campo de seleção de Empresas -->
          <div class="form-step">
            <div class="circle-number"> 6 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'manage-user.select-company-create' | translate}}</mat-label>
              <mat-select multiple>
                <mat-option (click)="selectAllCompanies(checkAllCompany)" #checkAllCompany [disabled]="true">
                  {{'manage-user.select-all' | translate}}
                </mat-option>
                <mat-checkbox color="primary" (change)="checkCompany(companyCreate.id)"
                  [checked]="companyCreate.checked" *ngFor="let companyCreate of companyList" [id]="companyCreate.id"
                  [disabled]="companiesOptionsDisabled && !companyCreate.checked">{{
                  companyCreate.reference }}</mat-checkbox>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo de seleção de Permissões -->
          <div class="form-step">
            <div class="circle-number"> 7 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'manage-user.select-permissions-create' | translate}} </mat-label>
              <mat-select multiple>
                <mat-option (click)="selectAllPermissions(selectAllPermission)" #selectAllPermission [disabled]="true">
                  {{'manage-user.select-all' | translate}}</mat-option>
                <mat-checkbox color="primary" (change)="checkPermission(permission.id)" [checked]="permission.checked"
                  *ngFor="let permission of permissionList" [id]="permission.id"
                  [disabled]="permissionsOptionsDisabled && !permission.checked">
                  {{permission.reference}}
                </mat-checkbox>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo de seleção de Grupos de Permissões -->
          <div class="form-step">
            <div class="circle-number"> 8 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'manage-user.select-permissions-group-create' | translate}} </mat-label>
              <mat-select multiple>
                <mat-option (click)="selectAllPermissionGroup(selectAllPermissionsGroupCreate)"
                  #selectAllPermissionsGroupCreate [disabled]="true">
                  {{'manage-user.select-all' | translate}}</mat-option>
                <mat-checkbox color="primary" (change)="checkPermissionGroup(permissionGroup.id)"
                  [checked]="permissionGroup.checked" *ngFor="let permissionGroup of permissionGroupList"
                  [id]="permissionGroup.id" [disabled]="permissionsGroupOptionsDisabled && !permissionGroup.checked">
                  {{permissionGroup.reference}}
                </mat-checkbox>
              </mat-select>
            </mat-form-field>
          </div>

          <!--Check box super usuário -->
          <mat-checkbox class="form-check" color="primary" (change)="checkboxSuperuser($event)">{{
            "manage-user.create-form-super-user" | translate }}
          </mat-checkbox>

          <button *ngIf="!loadingCreateUser" class="btn btn-success mb-2 w-100" (click)="createUser()"
            [disabled]="createUserForm.invalid">
            {{ "manage-user.create-form-btn-success" | translate }}
          </button>

          <button *ngIf="!loadingCreateUser" (click)="cancelCreateOrFilter()" type="button"
            class="btn btn-danger mb-2 w-100">
            {{ "manage-user.create-form-btn-danger" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="loadingCreateUser"></mat-spinner>
        </div>

        <!-- Box para buscar usuário -->
        <div class="form-fields-box" [ngClass]="!isFilterUser ? 'closed' : ''">
          <h2 (click)="filterUserBox()">
            {{ "manage-user.filter-form-title" | translate }}
          </h2>
          <hr [ngClass]="!isFilterUser ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "manage-user.filter-form-user" | translate }}</mat-label>
              <input [formControl]="usernameFilterForm" matInput type="text" #username_Icontains />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "manage-user.filter-form-email" | translate }}</mat-label>
              <input [formControl]="emailFilterForm" matInput type="text" #email_Icontains />
            </mat-form-field>
          </div>

          <mat-checkbox class="form-check" color="primary" (change)="checkboxSuperuser($event)" #isSuperuser>{{
            "manage-user.filter-form-super-user" | translate }}
          </mat-checkbox>
          <div class="d-flex m-2">
            <button (click)="filterUser(null)" class="btn btn-success mb-2 w-50" *ngIf="!filterLoading">
              {{ "manage-user.filter-form-btn-success" | translate }}
            </button>
            <button (click)="cancelCreateOrFilter()" type="button" class="btn btn-danger mb-2 w-50"
              *ngIf="!filterLoading">
              {{ "manage-user.filter-form-btn-danger" | translate }}
            </button>
            <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
          </div>
        </div>
      </div>
    </div>

    <!--Tabela-->
    <div class="col-lg-8 col-xl-8 col-sm-12">
      <app-table-header [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total"></app-table-header>
      <mat-progress-bar mode="indeterminate" *ngIf="loadingUser"></mat-progress-bar>
      <div class="table-div mt-2 mt-lg-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "manage-user.table-name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>
              {{ "manage-user.table-username" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> {{"manage-user.table-email" | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>

          <ng-container matColumnDef="lastaccess">
            <th mat-header-cell *matHeaderCellDef>
              {{ "manage-user.table-lastaccess" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="{{ 'time-zone-information-label' | translate }}" matTooltipPosition="above">
                {{ element.lastLogin }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="superuser">
            <th mat-header-cell *matHeaderCellDef>
              {{ "manage-user.table-super-user" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.superuser" svgIcon="bx-check"></mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="isactive">
            <th mat-header-cell *matHeaderCellDef>
              {{ "manage-user.user-is-active" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.isActive" svgIcon="bx-check"></mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{'manage-user.table-user-data' | translate}}</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view"
                (click)="OpenSidebarUpdate(element, false)">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>

              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view"
                (click)="OpenSidebarUpdate(element, true)">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!--Paginator-->
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageChanged($event)"
          aria-label="Selecione as páginas de Usuários" class="mat-paginator-sticky" showFirstLastButtons>
        </mat-paginator>
      </div>

    </div>

    <!-- Componente de edição -->
    <div class="sidebar-edit" *ngIf="isUpdateUser" [formGroup]="updateUserForm">
      <mat-icon (click)="closeSidebarUpdate()">clear</mat-icon>
      <h2 *ngIf="viewController === false"> {{ "manage-user.edit-title" | translate }} </h2>
      <h2 *ngIf="viewController === true"> {{ "manage-user.view-title" | translate }} </h2>
      <hr />

      <mat-form-field appearance="outline">
        <mat-label> {{ "manage-user.name-form-edit" | translate }} </mat-label>
        <input matInput formControlName="firstNameUpdateForm" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label> {{ "manage-user.last-name-form-edit" | translate }} </mat-label>
        <input matInput formControlName="lastNameUpdateForm" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "manage-user.user-form-edit" | translate }}
        </mat-label>
        <input matInput formControlName="userNameUpdateForm" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> {{ "manage-user.email-form-edit" | translate }} </mat-label>
        <input matInput formControlName="emailUpdateForm" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "manage-user.password-form-edit" | translate }}
        </mat-label>
        <input matInput formControlName="passwordUpdateForm" />
      </mat-form-field>

      <div style="align-self: start !important; list-style-type: none;">
        <li style="color:red;" *ngIf="updateUserForm.get('passwordUpdateForm')?.hasError('minlength')">
          - {{ "manage-user.password-character-validator" | translate }}
        </li>
        <li class="mb-5" style="color:red;" *ngIf="updateUserForm.get('passwordUpdateForm')?.hasError('pattern')">
          - {{ "manage-user.password-validatior" | translate }}
        </li>
      </div>

      <mat-checkbox class="form-check-sidebar" color="primary" formControlName="superUserUpdateForm"
        (change)="checkboxSuperuser($event)">{{"manage-user.super-user-edit" | translate }}
      </mat-checkbox>

      <mat-checkbox class="form-check-sidebar" color="primary" formControlName="isactiveUserUpdateForm"
        (change)="checkboxActiveuser($event)">{{"manage-user.active-user-edit" | translate }}
      </mat-checkbox>

      <!-- Seleção de empresas -->
      <mat-form-field appearance="outline">
        <mat-label> {{"manage-user.select-company-edit" | translate}} </mat-label>
        <mat-select multiple>

          <!-- Método será chamado quando todas as empresas forem selecionadas -->
          <mat-option (click)="selectAllCompaniesUpdate(valueCompany)" #valueCompany [disabled]="true">
            {{'manage-user.select-all' | translate}}
          </mat-option>

          <!-- Método é chamado sempre que houver uma alteração nas empresas selecionadas   -->
          <mat-checkbox color="primary" (change)="checkCompanyUpdate(companyEdit)" [checked]="companyEdit.checked"
            *ngFor="let companyEdit of companyListUpdate" [id]="companyEdit.id" [disabled]="companyEdit.disabled">
            {{companyEdit.reference }}
          </mat-checkbox>
        </mat-select>
      </mat-form-field>

      <!-- Seleção de permissões -->
      <mat-form-field appearance="outline">
        <mat-label> {{'manage-user.select-permission-edit' | translate}} </mat-label>
        <mat-select multiple>
          <mat-option (click)="selectAllPermissionsUpdate(valuePermission)" #valuePermission [disabled]="true">
            {{'manage-user.select-all' | translate}}
          </mat-option>
          <mat-checkbox color="primary" (change)="checkPermissionUpdate(permission)" [checked]="permission.checked"
            *ngFor="let permission of permissionListUpdate" [id]="permission.id" [disabled]="permission.disabled">
            {{permission.reference }}
          </mat-checkbox>
        </mat-select>
      </mat-form-field>

      <!-- Seleção de Grupos se Permissão -->
      <mat-form-field appearance="outline">
        <mat-label> {{'manage-user.select-permission-group-edit' | translate}} </mat-label>
        <mat-select multiple>
          <mat-option (click)="selectAllPermissionsGroupUpdate(allPermissionsGroupEdit)" #allPermissionsGroupEdit
            [disabled]="true">
            {{'manage-user.select-all' | translate}}
          </mat-option>
          <mat-checkbox color="primary" (change)="checkPermissionGroupUpdate(permissionGroup)"
            [checked]="permissionGroup.checked" *ngFor="let permissionGroup of permissionGroupListUpdate"
            [id]="permissionGroup.id" [disabled]="permissionGroup.disabled">
            {{permissionGroup.reference}}
          </mat-checkbox>
        </mat-select>
      </mat-form-field>
      <button (click)="updateUser()" class="btn btn-success" *ngIf="!loadingUpdateUser && !viewController">
        {{ "manage-user.button-save-edit" | translate }}
      </button>
      <!-- Spinner de loading de update de usuario -->
      <mat-progress-spinner *ngIf="loadingUpdateUser" mode="indeterminate" diameter="25">
      </mat-progress-spinner>

    </div>
  </div>
</div>