import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import { tzConvertISOStartDate, tzConvertISOEndDate, tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import Swal from 'sweetalert2';
import { PaginationInfo } from '../installation-log/installation-log-assets/installation-log-model';
import { userTraceLogService } from 'src/shared/services/user-trace-logs.service';
import { Company, User, UserTraceLog } from './user-trace-log-assets/user-trace-log-model';
import { MatDialog } from '@angular/material/dialog';
import { CompaniesService } from 'src/shared/services/companies.service';

@Component({
  selector: 'app-user-trace-log',
  templateUrl: 'user-trace-log.component.html',
  styleUrl: 'user-trace-log.component.less',
})
export class UserTraceLogComponent extends Unsubscriber implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private userTraceLogService: userTraceLogService,
    private companyService: CompaniesService,
    private errorLibService: ErrorLibService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() { }

  // Armazena o ID da empresa selecionada (Pode iniciar com o valor armazenado localStorage ou null)
  public companyId: string | null = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  // Variaveis responsáveis por abrir e fechar as boxes
  public filterBox: boolean = false;
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  // Variaveis responsáveis pelos loadings da tela
  public filterLoading: boolean = false;
  public fileLoading: boolean = false;
  public companyLoadingFilter: boolean = false;

  // Função responsável por mudar o estado da box de filtro
  filterBoxOpen(): void {
    this.filterBox = !this.filterBox;
  }

  /** Variáveis utilizadas para armazenar o filtro do usuário **/
  public queryName: string;
  public startDatetime: any;
  public endDatetime: any;
  public username: string;

  // Variável responsável pelo filtro de empresas
  public companyListFilter: Company[] = [];

  //Variavel responsável por armazenas os dados da tabela
  public ELEMENT_DATA: UserTraceLog[] = [];

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] = ["username", "datetime", "operation", "query", "payload"];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<UserTraceLog>(this.ELEMENT_DATA);

  public choosenPayload: any;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  /** Abre o modal que exibe o payload do log **/
  public openDialog(element: any): void {
    this.choosenPayload = this.formatJson(element.payload);
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Variáveis utilizadas para manipulação do Paginator **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      // Caso seja solicitada a próxima página manda os campos de startCursor e First
      this.filterUserTraceLog(this.paginationProperties.startCursor, this.pageSize, null)
    } else if (event.previousPageIndex < event.pageIndex) {
      // Caso seja solicitada a página anterior manda os campos de endCursor e Last
      this.filterUserTraceLog(null, this.pageSize, this.paginationProperties.endCursor)
    }
  }

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public FILE_DATA: UserTraceLog[] = [];
  public isReady: boolean = false;

  /**
   * Função que configura os dados da tabela.
   */
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  // Armazena os dados do formulário de filtro de logs, o valor é setado automáticamente pelo HTML
  public filterLogsForm: FormGroup = this.formBuilder.group({
    queryName: [null],
    company: [null],
    companySelected: [null],
    startDateForm: [null],
    endDateForm: [null],
    username: [null],
  });

  /**
   * Função responsavel por filtrar os logs
   */
  public filterUserTraceLog(startCursor: string | null, first: number | null, endCursor: string | null) {

    /** Caso todos os campos do formulário sejam válidos **/
    if (this.filterLogsForm.valid) {
      this.filterLoading = true;

      // Verifica quais campos de filtro foram preenchidos
      this.queryName = this.filterLogsForm.get('queryName')?.value || null;

      this.startDatetime = this.filterLogsForm.get('startDateForm')?.value
        ? tzConvertISOStartDate(this.filterLogsForm.get('startDateForm')?.value)
        : null;

      this.endDatetime = this.filterLogsForm.get('endDateForm')?.value
        ? tzConvertISOEndDate(this.filterLogsForm.get('endDateForm')?.value)
        : null;

      this.username = this.filterLogsForm.get('username')?.value || null;

      let company = this.filterLogsForm.get('companySelected')?.value || null;

      console.log(this.filterLogsForm.get('companySelected')?.value)

      // Filtra os logs na API
      this.subscriptions = this.userTraceLogService.filterLogs(
        company,
        this.queryName,
        this.startDatetime,
        this.endDatetime,
        this.username,
        startCursor,
        endCursor,
        first,
      ).valueChanges.subscribe({
        next: ((response: any) => {
          // Armazena as variáveis utilizadas para paginação
          this.paginationProperties = new PaginationInfo(
            response.data.userTraceLog.count,
            response.data.userTraceLog.total,
            response.data.userTraceLog.pageInfo.hasNextPage,
            response.data.userTraceLog.pageInfo.hasPreviousPage,
            response.data.userTraceLog.pageInfo.startCursor,
            response.data.userTraceLog.pageInfo.endCursor
          )
          // Limpa os dados que populam a tabela
          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;


          if (response.data.userTraceLog.pageInfo.hasPreviousPage === false) {
            this.pageIndex = 0;
          }

          // Adiciona cada log retornado pela requisição na lista
          response.data.userTraceLog.edges.forEach((node: any) => {
            this.ELEMENT_DATA.push(new UserTraceLog(
              node.node.datetime,
              new User(
                node.node.user.id,
                node.node.user.username,
              ),
              node.node.operation,
              node.node.userAgent,
              node.node.userAddress,
              node.node.query,
              node.node.payload,
            ));
          });
          // Atualiza a tabela com os novos dados
          this.setDataSourceAttributes();

          this.filterLoading = false;

          if (this.ELEMENT_DATA.length === 0) {
            // Retorna um aviso de erro ao usuário
            Swal.fire({
              title: 'Não Encontrado',
              text: 'Não há registros encontrados para esta busca.',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
          }
        })
      });
    } else {
      /** Caso algum campo não tenha sido preenchido, emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'É necessário preencher o campo de nome de usuário ou escolher uma empresa para realizar esse filtro',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método para realizar o filtro das empresas (utilizado apenas no formulário de filtro) **/
  public searchCompanyFilter(name: string | null): any {
    /** Limpa a lista de empresas sempre que o usuário clicar para buscar **/
    this.companyListFilter = [];
    /** Ativa o loading na tela **/
    this.companyLoadingFilter = true;
    /** Realiza a requisição que filtra as empresas **/
    this.subscriptions = this.companyService
      .filterCompanies(name, null, 100).valueChanges
      .subscribe({
        next: ((response: any) => {
          response.data.company.edges.forEach((node: any) => {
            /** Preenche a lista de empresas com os dados existentes **/
            this.companyListFilter.push(new Company(
              node.node?.id,
              node.node?.name,
            ));
          })
          /** Caso a lista esteja vazia **/
          if (this.companyListFilter.length === 0) {
            /** Exibe alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }
          /** Após a requisição ser realizada, desativa o loading **/
          this.companyLoadingFilter = false;
        }),
        /** Caso ocorra algum erro **/
        error: ((error: any) => {
          /** Exibe alerta ao usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.companyLoadingFilter = false;
        })
      });
  }

  // ---------------------------------------------------------------------
  // Código destinado à funções de download de CSV e PDF da página.

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.userTraceLogService.filterLogs(
        this.companyId,
        this.queryName,
        this.startDatetime,
        this.endDatetime,
        this.username,
        cursor,
        null,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {
          response.data.userTraceLog.edges.forEach((node: any) => {

            console.log("is inside request response with status: ")
            console.log(this.isReady)
            /** Tratamento de dados retornados da API **/
            this.FILE_DATA.push(new UserTraceLog(
              node.node.datetime,
              new User(
                node.node.user.id,
                node.node.user.username,
              ),
              node.node.operation,
              node.node.userAgent,
              node.node.userAddress,
              node.node.query,
              node.node.payload,
            ));
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.userTraceLog?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.userTraceLog?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content
      .map((item) => {
        return Object.values(item).join(';');
      })
      .join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((log) => {
      content.push({
        Username: log.user.username,
        QueryName: log.query,
        datetime: tzConvertUTC2Local(log.datetime),
        Company: this.companyId,
        Operation: log.operation,
        Payload: JSON.stringify(log.payload)
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-user-trace-log.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de c lick para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do PDF.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = ['Username', 'Operation', 'datetime', 'query', 'payload', 'Company'];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((log) => {
      content.push([
        log.user.username,
        log.query,
        tzConvertUTC2Local(log.datetime),
        this.companyId
      ]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });
    // Gera o download do documento.
    file.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-user-trace-log.pdf`
    );

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Realiza a conversão da data p/ fuso horário do navegador ou fuso horário especificado pelo usuário 
 * (Utilizado na data exibida na tabela de logs) **/
  public formatDate(date: string): any {
    return tzConvertUTC2Local(date)
  }

  public formatJson(json: any): string {
    let jsonHtml = '';

    // Itera sobre as chaves do JSON
    for (const chave in json) {
      const valor = json[chave];

      // Verifica se o valor é um objeto (para casos que acontecem como o orderBy)
      if (typeof valor === 'object' && valor !== null) {
        jsonHtml += `<span class="json-key">"${chave}"</span>: ${this.formatObject(valor)}<br>`;
      } else {
        // Se não for objeto, exibe apenas chave e valor
        jsonHtml += `<span class="json-key">"${chave}"</span>: <span class="json-value">"${valor}"</span><br>`;
      }
    }

    return jsonHtml;
  }

  // Função auxiliar para formatar os objetos retornados no JSON
  public formatObject(obj: any) {
    let objectHtml = '{<br>';

    // Percorre todo o objeto do JSON
    for (const key in obj) {

      const value = obj[key];
      objectHtml += `\t<span class="json-key">"${key}"</span>: `;

      if (typeof value === 'object' && value !== null) {
        // Se o valor for um objeto, formata recursivamente
        objectHtml += this.formatObject(value);
      } else {
        // Caso contrário, exibe o valor
        objectHtml += `<span class="json-value">"${value}"</span>`;
      }
      objectHtml += '<br>';
    }
    objectHtml += '}';
    return objectHtml;
  }
}

// Custom validator criado para verificar se 1 dos 2 campos obrigatórios foi preenchido
// Essa requisição necessita que seja enviado pelo menos o companyId ou o user
export const atLeastOneHasValue = (fields: Array<string>) => {
  return (group: FormGroup) => {
    for (const fieldName of fields) {
      if (group.get(fieldName)!.value) {
        return null;
      }
    }
    return { fieldRequired: true };
  };
};