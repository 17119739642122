import { gql } from "apollo-angular";

//Query Usuários
const FILTER_USERS = gql`
  query User(
	$company: ID
	$username_Icontains: String
	$email_Icontains: String
	$isSuperuser: Boolean
	$after: String
	$first: Int
	$before: String
	$last: Int
) {
	user(
		isSuperuser: $isSuperuser
		company: $company
		username_Icontains: $username_Icontains
		email_Icontains: $email_Icontains
		after: $after
		first: $first
		before: $before
		last: $last
	) {
		edges {
			node {
				id
				username
				firstName
				lastName
				email
				isActive
				lastLogin
				isSuperuser
				userPermissions {
					edges {
						node {
							id
						}
					}
				}
				groups {
					edges {
						node {
							id
						}
					}
				}
				cpf
				companies {
					edges {
						node {
							id
							name
						}
					}
				}
			}
			cursor
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}
`;

const FILTER_SUPER_USER_STATUS = gql`
  query User(
	$username: String
) {
	user(
		username: $username
	) {
		edges {
			node {
				id
				username
				firstName
				lastName
				email
				isActive
				isSuperuser
			}
		}
	}
}
`;

const GET_USER_COMPANIES = gql`
query All($username: String) {
	user(username: $username) {
		edges {
			node {
				id
				username
				companies {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	}
}
`;

const GET_USER_GROUP_PERMISSIONS = gql`
query User($username: String) {
	user(username: $username) {
		edges {
			node {
				id
				username
				firstName
				email
				isActive
				groups {
					edges {
						node {
							name
						}
					}
				}
			}
		}
	}
}`;

//Mutation Criar Usuários
const USER_CREATE = gql`
  mutation UserCreate(
    $firstname: String!
    $lastname: String
    $companiesIds: [ID]
    $cpf: String
    $username: String!
    $password: String!
    $email: String!
    $isSuperuser: Boolean!
  ) {
    userCreate(
      input: {
        firstName: $firstname
        lastName: $lastname
        companiesIds: $companiesIds
        cpf: $cpf
        username: $username
        password: $password
        email: $email
        isSuperuser: $isSuperuser
      }
    ) {
      user {
        id
        username
        firstName
        lastName
        email
        lastLogin
        cpf
        isActive
      }
    }
  }
`;

const USER_UPDATE = gql`
  mutation userUpdate(
    $id: ID!
    $firstname: String!
    $lastname: String!
    $companiesIds: [ID]
    $cpf: String
    $email: String!
    $password: String
    $isSuperuser: Boolean!
    $expiredPassword: Boolean!
  ) {
    userUpdate(
      input: {
        id: $id
        firstName: $firstname
        lastName: $lastname
        companiesIds: $companiesIds
        cpf: $cpf
        email: $email
        password: $password
        isSuperuser: $isSuperuser
        expiredPassword:$expiredPassword
      }
    ) {
      user {
        id
        username
        firstName
        lastName
        email
        lastLogin
        cpf
        expiredPassword
        isActive
      }
    }
  }
`;

const USER_PROFILE_UPDATE = gql`
  mutation
  UserProfileUpdate(
    $firstname: String,
    $username: String,
    $email: String,
    $password: String
  ) {
    userProfileUpdate (
      input: {
        firstName: $firstname
        username: $username
        email: $email
        password: $password
        expiredPassword: false
      })
    {
      user {
        id
      }
    }
  }
`;
const USER_DEACTIVATION_QUERY = gql`
  mutation userDeactivate(
    $userId: ID!

  ){
    userDeactivate(input: {
      userId: $userId,

    }){
      user {
        id
      }
    }
  }
`;

const USER_ACTIVATION_QUERY = gql`
  mutation userReactivate(
    $userId: ID!,
    $password: String!
  ){
    userReactivate(input: {
      userId: $userId,
      password: $password

    }){
      user {
        id
      }
    }
  }
`;

const LOGIN_QUERY = gql`
mutation Login($username: String!, $password: String!) {
	login(input: { username: $username, password: $password }) {
		user {
			id
			preferences
			email
			token
			password
			username
			expiredPassword
			geoserverPassword
		}
	}
}`;

export {
  // Queries
  FILTER_USERS,
  GET_USER_COMPANIES,
  GET_USER_GROUP_PERMISSIONS,
  FILTER_SUPER_USER_STATUS,
  LOGIN_QUERY,
  // Mutations
  USER_CREATE,
  USER_UPDATE,
  USER_PROFILE_UPDATE,
  USER_DEACTIVATION_QUERY,
  USER_ACTIVATION_QUERY
};
