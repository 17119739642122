/**  Classe de instalação que contem as variaveis utilizadas na instalação **/
export class Installation {
  constructor(
    public id: string, //id da instalaçao
    public reference: string | null, // referencia
    public isActive: boolean | null, // status
    public hasMagneticKey: boolean | null, // chave magnética
    public applications: string | null, //aplicação
    public division: Division, // divisão
    public site: InstallationSite, //local de instalação
    public device: Device, // device que vai referenciar o tipo de equipamento
    public gateway: Gateway, //gateway reference
    public lampType: LampsType, // trazer o model do tipo de lumináriaz
  ) { }
}

/** Classe que contém as variáveis de divisão **/
export class Division {
  constructor(
    public id: string | null,
    public reference: string | null
  ) { }
}

/** Class que contém as variáveis de equipamentos **/
export class Device {
  constructor(
    public id: string | null,
    public serialNumber: string | null,
    public reference: string | null,
    public major: string | null,
    public minor: string | null,
    public revision: string | null
  ) { }
}

/** Classe que contém as variáveis de local de instalação **/
export class InstallationSite { // local de instalação
  constructor(
    public id: string | null,
    public reference: string | null,
    public number: string | null,
    public district: string | null,
    public state: string | null,
    public city: string | null,
    public country: string | null,
    public lat: string | null,
    public long: string | null,
    public street: string | null
  ) { }
}

/** Classe que contém as variáveis do gateway **/
export class Gateway {
  constructor(
    public id: string | null,
    public reference: string | null,
  ) { }
}

/** Classe que contém as variáveis de tipos de luminárias **/
export class LampsType {
  constructor(
    public id: string | null,
    public model: string | null
  ) { }
}
/** Classe que contém as variáveis de paginação **/
export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }
}
export class Devices {
  constructor(
    public serialNumber: string | null,
    public macAddress: string | null
  ) { }
}
export class DevicesLogs {
  constructor(
    public startDatetime: string | null,
    public device: Devices
  ) { }
}
