export class ReportTable {
    constructor(
        public id: string | null,
        public ready: boolean | null,
        public generationDate: string | null,
        public requestedBy: string | null,
        public reference: string | null,
        public reportType: string | null,
    ) { }
}

export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}

export class IndividualConsumptionModel {
    constructor(
        public reference: string | null,
        public startDateTime: string | null,
        public endDateTime: string | null,
        public macAddress: string | null,
        public timezone: string | null
    ) { }
}

export class StatusModel {
    constructor(
        public reference: string | null,
        public installationIdList: string[] | null,
        public startDatetime: string | null,
        public endDatetime: string | null,
        public timezone: string | null
    ) { }
}

export class EventsModel {
    constructor(
        public reference: string | null,
        public startDatetime: string | null,
        public endDatetime: string | null,
        public macAddress: string | null,
        public timezone: string | null
    ) { }
}


export class LastTransmissionModel {
    constructor(
        public reference: string | null,
        public timezone: string | null
    ) { }
}

export class SystemAlertsModel {
    constructor(
        public reference: string | null,
        public startDatetime: string | null,
        public endDatetime: string | null,
        public timezone: string | null,
    ) { }
}

export class InstallationRecordModel {
    constructor(
        public reference: string | null,
        public timezone: string | null
    ) { }
}

export class LatestDataModel {
    constructor(
        public reference: string | null,
        public timezone: string | null
    ) { }
}

export class MetereologicalReportModel {
    constructor(
        public reference: string | null,
        public startDatetime: string | null,
        public endDatetime: string | null,
        public macAddress: string | null,
        public timezone: string | null
    ) { }
}

export class CommandReportModel {
    constructor(
        public reference: string | null,
        public startDatetime: string | null,
        public endDatetime: string | null,
        public timezone: string | null
    ) { }
}

export class InstallationListModel {
    constructor(
        public reference: string | null,
    ) { }
}

export class NoTransmissionReportModel {
    constructor(
        public reference: string | null,
        public hours: number | null,
        public timezone: string | null
    ) { }
}

export class IndividualStatusReportModel {
    constructor(
        public reference: string | null,
        public startDatetime: string | null,
        public endDatetime: string | null,
        public macAddress: string | null,
        public timezone: string | null
    ) { }
}

export class FirstOnFirstOff {
    constructor(
        public reference: string | null,
        public targetDate: Date | null,
        public timezone: string | null
    ) { }
}

export class PossibleStatus {
    constructor(
        public reference: string | null,
        public targetDate: Date | null,
        public timezone: string | null
    ) { }
}
