import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { ManageUserService } from 'src/shared/services/manage-user.service';

@Component({
  selector: 'app-logs-form',
  templateUrl: './logs-form.component.html',
  styleUrls: ['./logs-form.component.less']
})
export class LogsFormComponent implements OnInit {

  constructor(
    private router: Router,
    private manageUserService: ManageUserService
  ) { }

  ngOnInit(): void {
    if (this.router.url !== '/logs') {
      this.typesFilter = this.router.url
    }
    this.checkSuperUser();
  }

  public isSuperUser: boolean = false;

  filter: boolean = true;

  Filter(): void {
    this.filter = !this.filter;
  }
  typesFilter: string | null = null;

  checkSuperUser() {
    this.manageUserService.isSuperUser(`${localStorage.getItem('username')}`).valueChanges
      .subscribe(
        {
          next: (result: any) => {
            this.isSuperUser = result.data.user.edges[0].node.isSuperuser;
          },
          error: (error: any) => {
            console.log(error);
          }
        }
      )
  }
  Router(router: any[]): void {
    if (this.router.url !== '/logs') {
      Swal.fire({
        title: 'Deseja mesmo alterar o formulário? Todas as alterações até o momento serão perdidas.',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Confirmar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(router);
        } else if (result.isDenied) {
          this.typesFilter = this.router.url
        }
      });
    }
    else {
      this.router.navigate(router);
    }
  }
}
