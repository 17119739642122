import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_COMPANIES } from 'src/app/graphql/companies.graphql';
import { GET_AVAILABLE_PERMISSIONS, FILTER_PERMISSIONS_GROUP, GROUP_SET_USER, REMOVE_PERMISSION, SET_PERMISSION } from 'src/app/graphql/permissions.graphql';
import {
  USER_UPDATE,
  USER_PROFILE_UPDATE,
  USER_ACTIVATION_QUERY,
  USER_DEACTIVATION_QUERY,
  USER_CREATE,
  FILTER_SUPER_USER_STATUS
} from 'src/app/graphql/users.graphql';
import { FILTER_USERS, GET_USER_COMPANIES } from 'src/app/graphql/users.graphql';

@Injectable({
  providedIn: 'root',
})
export class ManageUserService {

  constructor(
    private apollo: Apollo,
  ) {
    this.getAllUsers(); //requisição da query
  }

  company = localStorage.getItem('lastCompanySelected');

  private getAllUsers(): void {
    this.apollo
      .watchQuery({
        query: FILTER_USERS,
        fetchPolicy: 'no-cache',
        variables: {
          company: this.company,
        },
      })
  }

  /** Retorna todas as empresas **/
  getAllCompanies() {
    return this.apollo.watchQuery({
      query: FILTER_COMPANIES,
      fetchPolicy: 'no-cache',
      variables: {
        sort_dir: 'ASC',
        sort_field: 'NAME'
      }
    });
  }

  /** Retorna todas as permissões **/
  getAllPermission() {
    return this.apollo.watchQuery({
      query: GET_AVAILABLE_PERMISSIONS,
      fetchPolicy: 'no-cache'
    });
  }

  /** Para setar as permissões dos usuários **/
  setPermission(userId: string, permissionIds: string[]) {
    return this.apollo.mutate({
      mutation: SET_PERMISSION,
      fetchPolicy: 'no-cache',
      variables: {
        userId,
        permissionIds,
      },
    });
  }

  /** Para remover as permissões dos usuários   **/
  removePermission(userId: string, permission_rem: string[]) {
    return this.apollo.mutate({
      mutation: REMOVE_PERMISSION,
      variables: {
        userId,
        permission_rem,
      },
    });
  }

  /**  Para setar os grupos de permissões dos usuários  **/
  setPermissionGroup(id: string, groupIds: string[]) {
    return this.apollo.mutate({
      mutation: GROUP_SET_USER,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        groupIds,
      },
    });
  }

  /** Retorna todos os grupos de permissões **/
  getAllPermissionGroups() {
    return this.apollo.watchQuery({
      fetchPolicy: 'no-cache',
      query: FILTER_PERMISSIONS_GROUP,
      variables: {
        company: this.company,
        sort_dir: 'ASC',
        sort_field: 'NAME',
      },
    });
  }

  /** Realiza o filtro dos usuários **/
  filterUsers(
    username_Icontains: String,
    email_Icontains: String,
    isSuperuser: Boolean,
    cursor: String | null,
    pageSize: number | null
  ) {
    return this.apollo
      .watchQuery({
        query: FILTER_USERS,
        fetchPolicy: 'no-cache',
        variables: {
          company: this.company,
          username_Icontains,
          email_Icontains,
          isSuperuser,
          first: pageSize,
          after: cursor
        },
      })
  }

  /** Realiza o filtro dos usuários **/
  handlePreviousPage(
    username_Icontains: String,
    email_Icontains: String,
    isSuperuser: Boolean,
    cursor: String | null,
    pageSize: number | null
  ) {
    return this.apollo
      .watchQuery({
        query: FILTER_USERS,
        fetchPolicy: 'network-only',
        variables: {
          company: this.company,
          username_Icontains,
          email_Icontains,
          isSuperuser,
          last: pageSize,
          before: cursor
        },
      })
  }

  /** Retorna as companies que o usuario tem acesso **/
  getUserCompanies(username: String) {
    return (
      this.apollo
        .watchQuery({
          query: GET_USER_COMPANIES,
          fetchPolicy: 'no-cache',
          variables: {
            username,
            sort_dir: 'ASC',
            sort_field: 'NAME'
          },
        })
    );
  }

  userActivation(
    userId: string,
    password: string
  ) {

    let variables: any = {
      userId: userId,
      password: password
    }
    return (this.apollo.mutate({
      mutation: USER_ACTIVATION_QUERY,
      fetchPolicy: 'no-cache',
      variables: variables
    })
    );
  }

  userDeactivation(
    userId: string
  ) {
    let variables: any = {
      userId: userId
    }

    return (this.apollo.mutate({
      mutation: USER_DEACTIVATION_QUERY,
      fetchPolicy: 'no-cache',
      variables: variables
    })
    );
  }

  /** Retorna as companies que o usuario tem acesso **/
  getUser(username: String) {
    return (
      this.apollo
        .watchQuery({
          query: FILTER_USERS,
          fetchPolicy: 'no-cache',
          variables: {
            username_Icontains: username
          },
        })
    );
  }

  /** Retorna as companies que o usuario tem acesso **/
  isSuperUser(username: String) {
    return (
      this.apollo
        .watchQuery({
          query: FILTER_SUPER_USER_STATUS,
          fetchPolicy: 'no-cache',
          variables: {
            username
          },
        })
    );
  }

  /** Realiza a criação de usuários**/
  createUser(
    firstname: string,
    lastname: string,
    companiesIds: string[],
    username: string,
    password: string,
    email: string,
    isSuperuser: boolean
  ) {
    return (
      this.apollo
        .mutate({
          mutation: USER_CREATE,
          fetchPolicy: 'no-cache',
          variables: {
            firstname,
            lastname,
            companiesIds,
            username,
            password,
            email,
            isSuperuser,
          },
        })
    );
  }

  /** Realiza o update do usuário **/
  updateUser(
    id: string,
    companiesIds: string[],
    firstname: string,
    lastname: string,
    email: string,
    isSuperuser: boolean,
    expiredPassword: boolean,
    password?: any  // Torna a senha opcional usando o operador "?"

  ) {
    // Verifiqua se a senha foi fornecida e construa as variáveis da mutação
    const variables: any = {
      id: id,
      companiesIds: companiesIds,
      firstname: firstname,
      lastname: lastname,
      email: email,
      expiredPassword: expiredPassword,
      isSuperuser: isSuperuser
    };

    // Adiciona a senha às variáveis somente se ela for fornecida
    if (password) {
      variables.password = password;
    }

    return this.apollo.mutate({
      mutation: USER_UPDATE,
      fetchPolicy: 'no-cache',
      variables: variables,
    });
  }

  /** Realiza o update do usuário **/
  updateUserProfile(
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    expiredPassword: boolean,
    password?: string[]  // Torna a senha opcional usando o operador "?"

  ) {
    // Verifiqua se a senha foi fornecida e construa as variáveis da mutação
    const variables: any = {
      username: username,
      firstname: firstname,
      lastname: lastname,
      email: email,
      expiredPassword: expiredPassword,
    };

    // Adiciona a senha às variáveis somente se ela for fornecida
    if (password) {
      variables.password = password;
    }

    return this.apollo.mutate({
      mutation: USER_PROFILE_UPDATE,
      fetchPolicy: 'no-cache',
      variables: variables,
    });
  }
}
