import { gql } from 'apollo-angular';

/** query para filtrar as instalações **/
const FILTER_INSTALLATIONS = gql`
query Installation(
  $company: ID!
  $referenceContains: String
  $country: String
  $gateway: ID
  $state: String
  $city: String
  $district: String
  $street: String
  $devices: [ID]
  $pageSize: Int
  $cursor: String
  $sort_dir: Direction!
  $sort_field: InstallationOrderFields!
  $equipmentTypes: [ID]
  ) {
  installation(
    company: $company
    referenceContains: $referenceContains
    country: $country
	gateway: $gateway
    devices: $devices
    state: $state
    city: $city
    district: $district
    street: $street
    pageSize: $pageSize
    cursor: $cursor
	equipmentTypes: $equipmentTypes
    orderBy: {
      direction: $sort_dir
      field: $sort_field
      }
    ) {
    count
    total
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        reference
        isActive
        hasMagneticKey
        applications
        site {
		  id
          reference
          country
          state
          city
          district
          street
          number
          details
          geoposition {
            latitude
            longitude
          }
        }
        device {
          id
          serialNumber
          macAddress
          equipmentType {
            reference
            major
            minor
            revision
          }
        }
        gateway {
			id
          reference
        }
        parentInstallation {
          id
          reference
        }
        circuitBox {
          id
          sgIpc {
            id
          }
          sgGateway {
            id
          }
          sgConsumption {
            id
          }
        }
        lampType{
          id
          power
          manufacturer
          model
          brightness
          voltage
          current
          consumption
          dimmingType
          powerFactor
        }
        division{
          id
          reference
        }

      }
      cursor
    }
  }
}`;

/** Mutation para criação de instalação **/
const CREATE_INSTALLATION = gql`
mutation InstallationCreate(
	$reference: String!
	$channel: Int!
	$site: ID!
	$equipment: ID
	$gateway: ID
	$circuitBox: ID
	$parentInstallation: ID
	$lampType: ID
	$hasMagneticKey: Boolean
	$applications: [ApplicationTags],
	$division: ID
) {
	installationCreate(
		input: {
			reference: $reference
			deviceId: $equipment
			applications: $applications
			channel: $channel
			gateway: $gateway
			circuitBox: $circuitBox
			parentInstallation: $parentInstallation
			site: $site
			lampType: $lampType
			hasMagneticKey: $hasMagneticKey
			division: $division
		}
	) {
		installation {
			id
			reference
			applications
			gateway{
				id
				reference
			}
			device{
				id
				equipmentType{
					id
					reference
					major
					minor
					revision
				}
			}
			lampType {
				id
				power
				manufacturer
				model
				brightness
				voltage
				current
				consumption
				dimmingType
				powerFactor
			}
			division{
				id
				reference
			}
			circuitBox {
				id
				sgIpc {
					id
					equipmentType {
						reference
					}
					serialNumber
					macAddress
					company {
						id
						name
					}
				}
			}
		}
	}
}`;

/** Mutation para realizar a edição de instalações **/
const UPDATE_INSTALLATION = gql`
mutation InstallationUpdate(
	$id: ID!
	$reference: String!
	$channel: Int!
	$site: ID!
	$deviceId: ID
	$gateway: ID
	$circuitBox: ID
	$parentInstallation: ID
	$applications: [ApplicationTags]
	$isActive: Boolean!
	$hasMagneticKey: Boolean!
	$lampType: ID
	$division: ID
) {
	installationUpdate(
		input: {
			id: $id
			reference: $reference
			deviceId: $deviceId
			applications: $applications
			channel: $channel
			gateway: $gateway
			circuitBox: $circuitBox
			parentInstallation: $parentInstallation
			site: $site
			isActive: $isActive
			lampType: $lampType
			division: $division
			hasMagneticKey: $hasMagneticKey
		}
	) {
		installation {
			id
			reference
			hasMagneticKey
			isActive
      		applications
			site {
				id
				reference
				city
				country
				state
				street
				geoposition
				{
					latitude
					longitude
				}
			}
			device {
				id
				serialNumber
				macAddress
				equipmentType{
					id
					reference
					major
					minor
					revision
				}
			}
			lampType{
				id
				model
			}
			gateway {
				id
				reference
			}
			division{
				id
				reference
			}
		}
	}
}`;

/** Mutation para realizar a exclusão de instalações **/
const DELETE_INSTALLATION = gql`
mutation
InstallationUpdate($id: ID!) {
	installationDelete(input: { id: $id }) {
		message
	}
}`;

/** Mutation para alteração do status da instalação **/
const STATUS_INSTALLATION = gql`
mutation InstallationUpdate(
	$id: ID!
	$reference: String!
	$channel: Int!
	$site: ID!
	$deviceId: ID
	$gateway: ID
	$applications: [ApplicationTags]
	$isActive: Boolean!
) {
	installationUpdate(
		input: {
			id: $id
			reference: $reference
			deviceId: $deviceId
			applications: $applications
			channel: $channel
			gateway: $gateway
			site: $site
			isActive: $isActive
		}
	) {
		installation {
			id
			reference
			hasMagneticKey
			isActive
			applications
			site {
				id
				reference
				city
				country
				state
				street
				geoposition {
					latitude
					longitude
				}
			}
			device {
				id
				serialNumber
				macAddress
				equipmentType {
					id
					reference
					major
					minor
					revision
				}
			}
			lampType {
				id
				model
			}
			gateway {
				id
				reference
			}
			division {
				id
				reference
			}
		}
	}
}
`;

const INSTALLATION_NODE = gql`
query node($id: ID!) {
  node(id: $id) {
  ... on Installation {
    device{
      serialNumber
      }
    site {
      reference
      timezone
      country
      state
      city
      district
      street
      number
      details
      locationCode
      geoposition {
        latitude
        longitude
      }
    }
    }
  }
}`;

/** Query para realizar o filtro por referencia **/
const GET_INSTALLATION_SERIAL = gql`
query Installation($company: ID!, $referenceContains: String) {
	installation(company: $company, referenceContains: $referenceContains) {
		edges {
			node {
				id
				reference
				device {
					id
					serialNumber
					macAddress
					equipmentType {
						reference
						major
						minor
						revision
					}
				}
			}
		}
	}
}`;

const GET_DEVICE_HISTORY_LOGS = gql`
query DeviceChangelog($installation: ID!) {
	deviceChangelog(installation: $installation) {
		edges {
			node {
				startDatetime
				device {
					macAddress
					serialNumber
				}
			}
		}
	}
}`;

export {
	// Queries
	FILTER_INSTALLATIONS,
	GET_INSTALLATION_SERIAL,
	INSTALLATION_NODE,
	GET_DEVICE_HISTORY_LOGS,

	// Mutations
	CREATE_INSTALLATION,
	UPDATE_INSTALLATION,
	DELETE_INSTALLATION,
	STATUS_INSTALLATION,
};
