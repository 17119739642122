export class UserTraceLog {
    constructor(
        public datetime: string,
        public user: User,
        public operation: string,
        public userAgent: string | null,
        public userAddress: string | null,
        public query: string | null,
        public payload: string | null,
    ) { }
}

export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}


export class User {
    constructor(
        public id: string,
        public username: string,
    ) { }
}

export class Company {
    constructor(
        public id: string,
        public name: string,
    ) { }
}